<template>
  <TimelineInformation />
</template>

<script>
import TimelineInformation from "../components/FormSites/TimelineInformation.vue";
export default {
  components: { TimelineInformation }
};
</script>
