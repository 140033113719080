<template>
  <form role="form" aria-label="Auswahl der Beschäftigung." @submit.prevent>
    <div class="backdrop" v-if="optionsVisible" @click="clickBackdrop"></div>
    <div
      role="combobox"
      aria-expanded="false"
      :aria-owns="`menu-search-${rowId}`"
      aria-haspopup="listbox"
      class="selectBody"
      @click.self="open"
      :style="{ 'z-index': optionsVisible ? 100 : 0 }"
    >
      <input
        :id="`search-input-${rowId}`"
        type="text"
        aria-placeholder="Bitte Wählen"
        aria-label="Such Feld"
        aria-haspopup="true"
        :aria-controls="`menu-search-${rowId}`"
        aria-autocomplete="list"
        placeholder="Bitte Wählen..."
        :aria-activedescendant="searchFocusedItemId ? `${rowId}-search-${searchFocusedItemId}` : ''"
        v-model="selectedOption"
        @input="onInput"
        @focus="showOnFocus"
        @keydown.tab="close"
        @keydown="preventInputUpDown"
      />
      <BaseArrowDown
        class="arrow-down"
        role=""
        tabindex="0"
        aria-label="Menu öffnen"
        aria-haspopup="true"
        :aria-controls="`menu-dropdown-${rowId}`"
        @keydown.enter="toggleVisibility"
        @click="toggleVisibility"
      />
    </div>
    <div
      :id="`menu-dropdown-${rowId}`"
      :aria-labelledby="`search-input-${rowId}`"
      class="options-body"
      v-if="optionsVisible"
    >
      <div :aria-hidden="searching" v-if="searching" class="list-container">
        <BaseSearch
          :id="`menu-dropdown-search-${rowId}`"
          role="listbox"
          :idPrefix="rowId"
          :data="mainMenu"
          :inputString="selectedOption"
          :nav-context="searchNavigationContext"
          @item-selected="itemSelected"
          @item-focused="itemFocused"
        />
      </div>
      <div
        role="tree"
        v-else
        class="list-container"
        :aria-expanded="!searching"
        :aria-hidden="searching"
      >
        <BaseTreePage
          :id="`menu-dropdown-tree-${rowId}`"
          :idPrefix="rowId"
          :treePage="mainMenu"
          :isTopLevel="true"
          :nav-context="treeNavigationContext"
          :close-dropdown-context="closeDropdownContext"
          @item-selected="itemSelected"
          @group-selected="groupSelected"
          @nav-go-back="navGoBack"
        />
      </div>
    </div>
  </form>
</template>

<script>
import BaseArrowDown from "../SVG-Icons/BaseArrowDown";
import BaseTreePage from "./BaseTreePage.vue";
import BaseSearch from "./BaseSearch.vue";
import cloneDeep from "lodash.clonedeep";
import mitt from "mitt";
import {
  KEY_RETURN,
  KEY_RIGHT,
  KEY_LEFT,
  KEY_UP,
  KEY_DOWN,
  KEY_ESCAPE,
  KEY_HOME,
  KEY_END
} from "keycode-js";

export default {
  components: {
    BaseArrowDown,
    BaseTreePage,
    BaseSearch
  },
  props: [
    "modelValue",
    "optionsMenus",
    "rowId",
    "closeDropdownContext",
    "initVal",
    "resetContext"
  ],
  emits: ["update:modelvalue", "item-selected"],
  data() {
    return {
      treeNavigationContext: mitt(),
      searchNavigationContext: mitt(),
      searching: false,
      selectedOption: "",
      optionsVisible: false,
      mainMenu: null,
      searchFocusedItemId: null
    };
  },
  methods: {
    toggleVisibility(e) {
      if (this.optionsVisible) this.close();
      else this.open();

      e.target.blur();
    },
    open() {
      setTimeout(() => {
        this.optionsVisible = true;
        this.navigationIndex = -1;
        this.searching = false;

        //reset focus
        this.traverse(this.mainMenu, menu => {
          if (menu.focused) {
            menu.focused = false;
            return true;
          }
          return false;
        });

        if(!this.mainMenu[0]) {
          return;
        }
        this.mainMenu[0].focused = true;
      }, 10);
    },
    close() {
      this.optionsVisible = false;
      this.searching = false;
    },
    showOnFocus() {
      this.open();
    },
    groupSelected(page) {
      this.traverse(this.mainMenu, menu => {
        if (menu.focused) {
          menu.focused = false;
          return true;
        }
        return false;
      });

      page.subMenus[0].focused = true;
    },
    itemSelected(payload) {
      if(process.env.NODE_ENV === "development"){
        console.log("itemSelected | ", payload.item);
      }
      this.$emit("item-selected", payload.item);
      this.selectedOption =
        payload.item.key + " | " + payload.group + " > " + payload.item.title;

      this.$store.dispatch({
        type: "setEmployment",
        employment: this.selectedOption,
        rowId: this.rowId,
        itemId: payload.item.id
      });
      this.close();
    },
    onInput() {
      if (this.selectedOption === "") {
        this.$store.dispatch("removeEmployment", this.rowId)
        this.searching = false;
        this.resetFocus();

        if(!this.mainMenu[0]){
          return
        }

        this.mainMenu[0].focused = true;
      } else {
        this.searching = true;
      }
    },
    clickBackdrop() {
      this.optionsVisible = false;
    },
    navGoBack(treeGroup) {
      this.traverse(this.mainMenu, menu => {
        if (menu.focused) {
          menu.focused = false;
          return true;
        }
        return false;
      });

      treeGroup.focused = true;
    },
    navigate(event) {
      if (event.keyCode === KEY_DOWN) {
        if (this.searching) {
          this.searchNavigationContext.emit("nav-down");
        } else {
          this.traverse(this.mainMenu, menu => {
            if (menu.focused && menu.next) {
              menu.focused = false;
              menu.next.focused = true;
              this.treeNavigationContext.emit("focus-elem", menu.next);
              return true;
            }
            return false;
          });
        }
      } else if (event.keyCode === KEY_UP) {
        if (this.searching) {
          this.searchNavigationContext.emit("nav-up");
        } else {
          this.traverse(this.mainMenu, menu => {
            if (menu.focused && menu.previus) {
              menu.focused = false;
              menu.previus.focused = true;
              this.treeNavigationContext.emit("focus-elem", menu.previus);
              return true;
            }
            return false;
          });
        }
      } else if (event.keyCode === KEY_LEFT) {
        if (!this.searching) {
          this.traverse(this.mainMenu, menu => {
            const focusedMenu = menu.subMenus?.find(subMenu => subMenu.focused);
            if (focusedMenu) {
              focusedMenu.focused = false;
              menu.focused = true;
              this.treeNavigationContext.emit("nav-back", {
                prevFocusedMenu: focusedMenu,
                newFocusedMenu: menu
              });
              return true;
            }
            return false;
          });
        }
      } else if (event.keyCode === KEY_RIGHT || event.keyCode === KEY_RETURN) {
        // KeyCode 13 => Enter
        // Arrow right => KeyCode 39
        if (this.searching) {
          if (event.keyCode === KEY_RETURN) {
            this.searchNavigationContext.emit("nav-selected");
          }
        } else {
          this.traverse(this.mainMenu, menu => {
            if (menu.focused && menu.subMenus) {
              this.groupSelected(menu);
              this.treeNavigationContext.emit("nav-navigate", {
                newMenuGroup: menu
              });
              return true;
            } else if (
              menu.focused &&
              !menu.subMenus &&
              event.keyCode === KEY_RETURN
            ) {
              this.treeNavigationContext.emit("nav-selected", {
                selectedMenu: menu
              });
            }
            return false;
          });
        }
      } else if (
        (event.keyCode === KEY_HOME || event.keyCode === KEY_END) &&
        this.searching
      ) {
        this.searchNavigationContext.emit("nav-clear");
      }
    },
    keyDown(event) {
      if (this.optionsVisible) {
        if (event.keyCode === KEY_ESCAPE) {
          this.close();
          this.selectedOption = "";

          this.$store.dispatch("removeEmployment", this.rowId);
        } else {
          this.navigate(event);
        }
      }
    },
    traverse(menus, callback) {
      for (const menu of menus) {
        if (callback(menu)) return true;
        if (menu.subMenus && menu.subMenus.length > 0) {
          if (this.traverse(menu.subMenus, callback)) return true;
        }
      }
      return false;
    },
    linkObjectsForNavigation(menus) {
      if(!menus) {
        if(process.env.NODE_ENV === "development"){
          console.error("BaseTree | linkObjectsForNavigation => Parameter menus is null");
        }
        return;
      }

      for (let i = 0; i < menus.length; i++) {
        if (i - 1 >= 0) {
          menus[i].previus = menus[i - 1];
        }
        if (i + 1 < menus.length) {
          menus[i].next = menus[i + 1];
        }
        if (menus[i].subMenus) {
          this.linkObjectsForNavigation(menus[i].subMenus);
        }
      }
    },
    preventInputUpDown(e) {
      if (e.keyCode === KEY_UP || e.keyCode === KEY_DOWN) {
        e.preventDefault();
      }
    },
    resetFocus() {
      this.traverse(this.mainMenu, menu => {
        menu.focused = false;
        return false;
      });
    },
    itemFocused(id) {
      this.searchFocusedItemId = id;
    },
    onCloseEvent() {
      if(process.env.NODE_ENV === "development"){
        console.log("Close Dropdown");
      }
      this.close();
    },
    reset() {
      this.selectedOption = "";
    }
  },
  created() {
    this.mainMenu = cloneDeep(this.optionsMenus);
    this.linkObjectsForNavigation(this.mainMenu);

    if(!this.mainMenu[0]){
      return;
    }
    this.mainMenu[0].focused = true;
  },
  mounted() {
    if (this.initVal) this.selectedOption = this.initVal;
    else this.selectedOption = "";
    document.addEventListener("keydown", this.keyDown);
    this.closeDropdownContext.on("close-dropdown", this.onCloseEvent);
    this.resetContext?.on("reset", this.reset);
  },
  unmounted() {
    document.removeEventListener("keydown", this.keyDown);

    this.closeDropdownContext.off("close-dropdown", this.onCloseEvent);
    this.resetContext?.off("reset");
  }
};
</script>

<style scoped>
.selectBody {
  width: 290px;
  height: 40px;

  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem;

  position: relative;
  cursor: pointer;
  z-index: 100;
}

input {
  border: 0px;
}

input:focus {
  outline: none;
}

.option-margin-left {
  margin-left: 1rem;
}

.options-body {
  position: absolute;
  top: inherit;
  left: inherit;
  z-index: 101;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px;
  width: 480px;
  /* height: 296px; */

  background: #ffffff;
  /* color-neutral-400 */

  border: 1px solid #d9dada;
  box-sizing: border-box;
  /* shadow-medium */

  box-shadow: 0px 1px 2px rgba(21, 31, 45, 0.15),
    0px 4px 8px rgba(21, 31, 45, 0.05);

  border-radius: 4px;
}

.list-container {
  width: inherit;
}

.highlight {
  background-color: rgba(64, 83, 255, 0.733);
  border-radius: 4px;
}

.option-header-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 7px;
}

.sp-between {
  justify-content: space-between;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
}

.pageination-item,
.pageination-arrow {
  margin: 0px 5px;
}

.pageination-item {
  font-weight: bold;
}

.pageination-arrow:hover {
  cursor: pointer;
}

.arrow-down {
  padding: 5px;
}

.arrow-down:focus {
  border: 2px solid #d9dada;
  border-radius: 5px;
}

.backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}
</style>
