<template>
  <div role="listbox" class="grid sm:grid-cols-box-2 md:grid-cols-box-3 xl:grid-cols-box-3 gap-x-24" :aria-label="labeldBy">
    <div
      role="option"
      :id="`box-${compId}-${index}`"
      :aria-label="label + ' ' + box.text"
      :aria-selected="box.isSelected || false"
      class="selectbox-button text-centered "
      :class="{ selected: box.isSelected }"
      v-for="(box, index) in boxes"
      :key="box.id"
      tabindex="0"
      @click="$emit('select-box', box)"
      @keydown.enter="$emit('select-box', box)"
    >
      <div
        :aria-selected="box.isSelected"
        class="container-flex-column text-large-strong text-cc-blue-800"
        :class="{ 'text-selected': box.isSelected }"
      >
        <!-- <img v-if="box.imgSrc" :src="box.imgSrc" alt="Icon Verwaltungsdienst" /> -->
        <svg-image
          v-if="box.icon"
          :icon="box.icon"
          :is-selected="box.isSelected"
        >
        </svg-image>
        {{ box.text }}
        <small
          v-if="box.subText"
          class="text-small"
          :class="{ 'text-selected': box.isSelected }"
        >
          {{ box.subText }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import SvgImage from "./SVG-Icons/SvgImage";
export default {
  components: { SvgImage },
  props: {
    label: String,
    compId: String,
    boxes: Array,
    tabIndexe: Array,
    labeldBy: String
  },
  emits: ["select-box"],
  data() {
    return {
      focused: -1
    };
  },
  methods: {
    keyDown(event) {
      if (event.keyCode == 39) {
        if (this.focused + 1 < this.boxes.length) {
          this.focused++;
          const box = this.boxes[this.focused];
          this.$emit("select-box", box);
        }
      } else if (event.keyCode == 37) {
        if (this.focused - 1 >= 0) {
          this.focused--;
          const box = this.boxes[this.focused];
          this.$emit("select-box", box);
        }
      }
    }
  },
  mounted() {
    // document.addEventListener("keydown", this.keyDown);
  },
  unmounted() {
    // document.removeEventListener("keydown", this.keyDown);
  }
  // beforeMount() {
  //   let index = 0;
  //   this.boxStrings.forEach(text => {
  //     this.boxes.push({ id: index, text, isSelected: false });
  //   });
  // }
};
</script>

<style scoped>
.container-grid {
  /* border: 2px solid red; */
  display: grid;
  /* grid-auto-flow: column; */
  /* grid-template-columns: repeat(auto-fill, minmax(230px, 50fr));*/
  /* grid-template-rows: auto; */
  /* grid-template-areas: "side-nav main-content"; */

  /* column-gap: 1rem; */
  width: 60%;

  align-items: center;
  justify-content: flex-start;
}

.text-centered {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.selectbox-button {
  width: 300px;
  height: 125px;

  background: #ffffff;
  /* color-neutral-400 */

  border: 1px solid #d9dada;
  box-sizing: border-box;
  /* shadow-small */

  box-shadow: 0px 1px 3px rgba(56, 73, 97, 0.1);
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}

.selectbox-button:hover,
.selectbox-button:focus {
  /* color-blue-600 */

  border: 2px solid #125797;
  box-sizing: border-box;
  border-radius: 8px;

  cursor: pointer;
}

div:focus {
  outline: none;
}

.selected {
  background: #125797;
  border-radius: 8px;
}

.container-flex-column {
  align-items: center;
}
</style>
