import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";
import GeneralSite from "../views/GeneralSite";
// import Config from "../../vue.config";
import TimelineSite from "../views/TimelineSite.vue";
import TheResultSite from "../views/TheResultSite.vue";
import HelpPage from "../views/HelpPage.vue";
import TheVersorgungsrechner from "../views/TheVersorgungsrechner.vue";
// import BaseHelp from "../components/Help/BaseHelp.vue";
import TheHelpOverview from "../components/Help/TheHelpOverview.vue";
import TheHelpAgeLimit from "../components/Help/TheHelpAgeLimit.vue";
import TheHelpKeys from "../components/Help/TheHelpKeys.vue";
import TheHelpRules from "../components/Help/TheHelpRules.vue";
import TheHelpGroup from "../components/Help/TheHelpGroup.vue";
import TheHelpExample from "../components/Help/TheHelpExample.vue";
import PensionCalculatorMain from "@/views/PensionCalculatorPage";

const routes = [
  {
    path: "/",
    name: "Versorgungsrechner",
    component: TheVersorgungsrechner,
    redirect: { name: "General" },
    children: [
      {
        path: "/general",
        name: "General",
        component: GeneralSite
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () =>
        //   import(/* webpackChunkName: "about" */ "../views/About.vue")
      },
      {
        path: "/timeline",
        name: "Timeline",
        component: TimelineSite
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () =>
        //   import(/* webpackChunkName: "about" */ "../views/About.vue")
      },
      {
        path: "/result",
        name: "Result",
        component: TheResultSite
      }
    ]
  },
  {
    path:"/berechnung-ruhegehalt",
    name: "Berechnung",
    component: PensionCalculatorMain,
    meta: {title: "Ruhegehaltsrechner"}
  },
  {
    path: "/help",
    name: "Help",
    component: HelpPage,
    children: [
      {
        path: "einfuehrung",
        name: "Einfuehrung",
        component: TheHelpOverview
      },
      {
        path: "altersgrenze",
        name: "Altersgrenze",
        component: TheHelpAgeLimit
      },
      {
        path: "schluessel",
        name: "Schluessel",
        component: TheHelpKeys
      },
      {
        path: "regeln",
        name: "Regeln",
        component: TheHelpRules
      },
      {
        path: "laufbahn",
        name: "Laufbahn",
        component: TheHelpGroup
      },
      {
        path: "example",
        name: "Example",
        component: TheHelpExample
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory("/versorgungsrechner"),
  routes
});

router.beforeEach((to, _, next) => {
  document.title = to.meta.title || "LBV Versorgungsrechner";
  next();
})

export default router;
