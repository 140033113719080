<template>
<!--  <GeneralInformation class="content-container-flex" />-->
  <GeneralInformation class="flex flex-col justify-start items-stretch" />
</template>

<script>
import GeneralInformation from "../components/FormSites/GeneralInformation";

export default {
  components: {
    GeneralInformation
  }
};
</script>

<style scoped>
/*.content-container-flex {*/
/*  display: flex;*/
/*  flex-flow: column;*/
/*  justify-content: flex-start;*/
/*  align-items: stretch;*/
/*  flex-shrink: initial;*/
/*}*/
</style>
