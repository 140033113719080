<template>
  <link rel="stylesheet" href="../../../../../LBV/Rechner/oldGlobalStyles.css">
  <div>
    <div
      role="tab"
      aria-label="1 Allgemeine Angaben"
      aria-roledescription="Clicken um auf Seite zu wechseln"
      :aria-selected="activeSite === 'General'"
      tabindex="0"
      class="nav-item-container"
      :class="{'cursor-pointer': currRoute !== 'General'}"
      @click="navigateToGeneral"
    >
      <!-- <div class="text nav-number circle circle-gray">1</div> -->
      <div
        tabindex="-1"
        class="flex justify-center items-center circle nav-number"
        :class="{
          'nav-number-white': activeSite === 'General',
          'circle-blue': activeSite === 'General',
          'circle-green': activeSite !== 'General'
        }"
      >
        <div v-if="activeSite === 'General'">
          1
        </div>
        <CheckMark aria-label="Haken" v-else />
      </div>
      <span
        tabindex="-1"
        class="nav-text"
        :class="{
          'text-green': activeSite !== 'General',
          'text-blue': activeSite === 'General'
        }"
      >
        Allgemeine Angaben
      </span>
    </div>
    <div
      role="tab"
      aria-label="2 Zeiträume"
      aria-roledescription="Klicken um auf Seite zu wechseln"
      :aria-selected="activeSite === 'Timeline'"
      tabindex="0"
      class="nav-item-container"
      :class="{ 'cursor-not-allowed': hasGeneralpageErr,'cursor-pointer': !hasGeneralpageErr && currRoute !== 'Timeline'}"
      @click="navigateToTimes"
    >
      <!-- <div class="text nav-number circle circle-gray">1</div> -->
      <div
        tabindex="-1"
        class="flex justify-center items-center  circle nav-number"
        :class="{
          'nav-number-white': activeSite === 'Timeline',
          'circle-blue': activeSite === 'Timeline',
          'circle-green': activeSite !== 'General' && activeSite !== 'Timeline'
        }"
      >
        <div v-if="activeSite === 'Timeline' || activeSite === 'General'">
          2
        </div>
        <CheckMark v-else />
      </div>
      <span
        tabindex="-1"
        class="nav-text"
        :class="{
          'text-green': activeSite !== 'General' && activeSite !== 'Timeline',
          'text-blue': activeSite === 'Timeline'
        }"
      >
        Zeiträume
      </span>
    </div>
    <div
      role="tab"
      aria-label="3 Ergebnis"
      aria-roledescription="Clicken um auf Seite zu wechseln"
      aria-disabled="true"
      tabindex="0"
      class="nav-item-container"
    >
      <!-- <div class="text nav-number circle circle-gray">1</div> -->
      <div
        tabindex="-1"
        class="flex justify-center items-center circle nav-number"
        :class="{
          'nav-number-white': activeSite === 'Result',
          'circle-blue': activeSite === 'Result'
        }"
      >
        3
      </div>
      <span tabindex="-1" class="nav-text">Ergebnis</span>
    </div>
  </div>
</template>

<script>
import CheckMark from "./FormSites/GeneralFormComponents/UiElements/SVG-Icons/CheckMark";
export default {
  components: {
    CheckMark
  },
  data() {
    return {
      siteActive: ""
    };
  },
  computed: {
    activeSite() {
      return this.$route.name;
    },
    hasGeneralpageErr(){
      return this.$store.getters.hasGeneralPageErrs;
    },
    currRoute(){
      return this.$route.name;
    }
  },
  methods: {
    navigateToGeneral() {
      this.$router.push({ name: "General" });
    },
    navigateToTimes() {
      if(this.hasGeneralpageErr)
        return;

      this.$router.push({ name: "Timeline" });
    }
  }
};
</script>

<style scoped>
.circle {
  background-color: #eceded;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.circle-blue {
  background-color: #1b4265;
}

.circle-green {
  background-color: #009640;
}

.nav-number {
  grid-area: nav-number;

  font-family: "Libre Franklin", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;

  color: #7b7b7b;

  /* border: 2px solid pink; */
}

.nav-number-white {
  grid-area: nav-number;

  font-family: "Libre Franklin", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;

  color: #ffffff;

  /* border: 2px solid pink; */
}

.nav-text {
  grid-area: nav-text;

  font-family: "Noto Sans", sans-serif;
  /* font-family: 'Big Shoulders Stencil Display', cursive; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  /* color: #7b7b7b; */
  color: #535353;
  /* color: #ACACAC; */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border: 2px solid pink; */
}

.nav-item-container {
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-rows: auto;
  grid-template-areas: "nav-number nav-text";

  column-gap: 1.5rem;

  margin-bottom: 1rem;
}

.nav-item-container:focus {
  outline: none;
}

.text-green {
  color: #009640;
}

.text-blue {
  color: #1b4265;
}

.nav-item-container:hover {
  /*cursor: pointer;*/
}
</style>
