import { createStore } from "vuex";

import { errorState } from "./errorState";
import {appState} from "@/store/appState";

export default createStore({
  modules: {
    errorState,
    appState
  }
});