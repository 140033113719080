import mitt from "mitt";
export const errorState = {
  state: () => ({
    generalPageErrs: [],
    generalPageErr: false,
    timelinePageErrs: [],
    timelineMissingInpts: [],
    generalPageErrCtx: mitt(),
    timelinePageErrCtx: mitt(),
    deleteErrorsCtx: mitt(),
    validationCtx: mitt()
  }),
  getters: {
    getGeneralPageErrs(state) {
      return state.generalPageErrs;
    },
    getTimelinePageErrs(state) {
      return state.timelinePageErrs;
    },
    getGeneralPageErrCtx(state) {
      return state.generalPageErrCtx;
    },
    getTimelinePageErrCtx(state) {
      return state.timelinePageErrCtx;
    },
    getValidationContext(state) {
      return state.validationCtx;
    },
    isStateValid(state) {
      return state.generalPageErrs.length === 0 && state.timelinePageErrs.length === 0;
    },
    hasGeneralPageErrs(state){
      return state.generalPageErr;
    }
  },
  mutations: {
    resetGeneralPageErr(state){
      state.generalPageErrs = [];
    },
    addGeneralPageErr(state, error) {
      if (state.generalPageErrs.find(err => err.id === error.id)) return;
      state.generalPageErrs.push(error);
      state.generalPageErr = true;
      if(process.env.NODE_ENV === "development"){
        console.log("Store | addGeneralPageErr => set generalPageErr to true");
        console.log("Store | addGeneralPageErr => generalPageErrs: ", state.generalPageErrs);
      }
      state.generalPageErrCtx.emit("err-added");
    },
    removeGeneralPageErr(state, id) {
      state.generalPageErrs = state.generalPageErrs.filter(err => err.id !== id);
      if(state.generalPageErrs.length === 0) {
        if(process.env.NODE_ENV === "development"){
          console.log("Store | removeGeneralPageErr => set generalPageErr to false!!!!!!");
        }
        state.generalPageErr = false;
      }
      if(process.env.NODE_ENV === "development"){
        console.log("Store | removeGeneralPageErr => removed");
        console.log("Store | removeGeneralPageErr => generalPageErrs: ", state.generalPageErrs);
      }
      state.generalPageErrCtx.emit("err-removed");
    },
    addTimelinePageErr(state, error) {
      if (state.timelinePageErrs.find(err => err.index === error.index)) return;
      state.timelinePageErrs.push(error);
      state.timelinePageErrCtx.emit("err-added", state.timelinePageErrs);
    },
    updateTimelinePageErr(state, { pubObj, objKey }) {
      if (!pubObj) return;
      let validationErr = state.timelinePageErrs.find(elem => elem.index === pubObj.index);

      if (validationErr && !validationErr[objKey].find(msgObj => msgObj.key === pubObj.inpElem)) {
        validationErr.period.push({
          key: pubObj.inpElem,
          msg: pubObj.msg
        });
        state.timelinePageErrCtx.emit("err-added", state.timelinePageErrs);
      }
    },
    removeTimelineValidationMsg(state, pubObj) {
      let validationErr = state.timelinePageErrs.find(elem => elem.index === pubObj.index);
      if(!validationErr) return;
      const msgObj = validationErr.from.find(msgObj => msgObj.key === pubObj.inpElem);
      if (msgObj) {
        validationErr.from.splice(validationErr.from.indexOf(msgObj), 1);
      }
    },
    /**
     *
     * @param state
     * @param {import("../types").validationType} validationObj
     */
    removeTimelinePageErrRow(state, {validationErr, force}) {
      if(!force){
        for (const key in validationErr) {
          if (Array.isArray(validationErr[key]) && validationErr[key].length > 0) {
            return;
          }
        }
      }

      state.timelinePageErrs.splice(state.timelinePageErrs.indexOf(validationErr), 1);
      state.timelinePageErrCtx.emit("err-removed", state.timelinePageErrs);
      if(force) {
        state.validationCtx.emit("rerun-validation", state.timelinePageErrs)
      }
    },
    /**
     *
     * @param state
     * @param {Boolean} hasErr
     */
    setGeneralPageErr(state, hasErr){
      state.generalPageErr = hasErr;
    },
  },
  actions: {
    resetGeneralPageErr(context) { context.commit("resetGeneralPageErr") },
    /**
     *
     * @param {import("vuex").ActionContext} context
     * @param { Object } error
     */
    addError(context, { id, elem, errMsg, errType }) {
      const error = {
        id: id,
        msg: errMsg,
        elem: elem,
        errType: errType
      };

      context.commit("addGeneralPageErr", error);
      // if(page === "GENERAL"){
      //   context.commit("addGeneralPageErr", error);
      // }else if(page === "GENERAL"){
      //   context.commit("addTimelinePageErr", error);
      // }
    },
    //########################################################
    addInputMissing(){

    },
    removeInputMissing(){

    },
    updateInputMissing(){

    },
    //########################################################
    /**
     *
     * @param {import("vuex").ActionContext} context
     * @param {String} id
     */
    removeGeneralPageErr(context, id) {
      context.commit("removeGeneralPageErr", id);
    },
    //########################################################

    addTimelineError(context, validationErr) {
      context.commit("addTimelinePageErr", validationErr);
    },
    updateTimelinePageErr(context, { pubObj, objKey }) {
      context.commit("updateTimelinePageErr", { pubObj, objKey });
    },
    removeTimelineValidationMsg(context, pubobj) {
      context.commit("removeTimelineValidationMsg", pubobj);
    },

      //
    /**
     * @param {import("vuex").ActionContext} context
     * @param {import("../types").validationType} validationObj
     */
    removeTimelineErrorRow(context, validationObj) {
      context.commit("removeTimelinePageErrRow", validationObj);
    },
    checkDateValidity(context, { dateToBeChecked, cb }) {
      const supplyBegin = new Date(context.getters.getBegin);

      if(isNaN(dateToBeChecked.getTime()) || isNaN(supplyBegin.getTime())){
        cb(true);
        return;
      }

      cb(dateToBeChecked < supplyBegin);
      if(process.env.NODE_ENV === "development"){
        console.log("checkDateValidity | dateToBeChecked", dateToBeChecked, ", supplyBegin " ,supplyBegin);
      }
    },
    /**
     * 
     * @param {import("vuex").ActionContext} context 
     * @param {(validitiy: Boolean) => void} cb 
     */
    isErrStateValid(context, cb){
      let stateValid = true;

      if(context.state.timelinePageErrs.length !== 0){
        stateValid = false;
      }

      cb(stateValid);
    },

    /**
     *
     * @param {import("vuex").ActionContext} context
     * @param {Boolean} hasErr
     */
    setGeneralPageErr(context, hasErr){
      context.commit("setGeneralPageErr", hasErr);
    }
  }
};
