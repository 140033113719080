<template>
  <div class="grid-container">
    <the-help-nav class="grid-item-links flex-container "></the-help-nav>
    <div role="main" class="grid-item-text">
      <router-view/>
    </div>
  </div>
  <div>

  </div>
  <section class="mr-1 flex flex-col justify-end items-start version-pos ml-8 text-gray-400">
    <div class="text-small-strong">Versorgungsrechner</div>
    <div class="text-small-strong">Version: {{ version || "Fehler beim Abfragen der Version" }}</div>
  </section>
</template>

<script>
import TheHelpNav from "../components/Help/TheHelpNav";

export default {
  components: {TheHelpNav},
  data() {
    return {
      version: ""
    }
  },
  mounted() {
    fetch(window.location.href.replace(/\/help\/.*/, "/rest/version"), {
      method: "GET"
    })
        .then(res => {
          if (res.status === 200) {
            return res.json()
          } else {
            return Promise.resolve({version: "0.0.1"})
          }
        })
        .then(version => {
          if (process.env.NODE_ENV === "development")
            console.log("HelpPage created | Version: ", version);
          this.version = version.version
        });
  }
};
</script>

<style scoped>
.version-pos {
  margin-bottom: 15px;
}

.grid-container {
  margin: 2rem;
  display: grid;
  grid-template-columns: 15% 83%;
  grid-template-rows: auto;
  grid-template-areas: "links text";
}

.grid-item-links {
  grid-area: links;
}

.grid-item-text {
  grid-area: text;
}

.flex-container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-item {
  width: 85%;
  height: 40px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.link-text {
  margin-left: 10px;
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  text-decoration-line: none;

  /* color-blue-800 */

  color: #0f3b63;
}

.link-text:hover {
  text-decoration-line: underline;
}

.selected-text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;

  /* color-neutral-100 */

  color: #ffffff;
}

.selected {
  background: #286093;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
</style>
