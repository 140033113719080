export default class {
    /**
     *
     * @type {Function}
     */
    _pollFun;

    get() {
        return this._pollFun;
    }

    /**
     *
     * @param pollFun
     */
    set(pollFun){
        this._pollFun = pollFun;
    }

    poll(){
        return this._pollFun();
    }
}