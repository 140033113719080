<template>
  <div
    v-if="!isTopLevel"
    v-show="Object.keys(showChildren).length === 0"
    class="title-container"
    @click="goBack"
  >
    <BaseBackArrow
      class="option-margin-left"
      style="cursor: pointer; margin-right: 1rem"
    />
    <span>{{ title }}</span>
  </div>
  <div
    class="select-option"
    :class="{ focused: page.focused }"
    v-for="page in treePage"
    :key="page.id"
  >
    <div
      :id="page.id"
      :role="'treeitem'"
      :aria-label="`${page.key ?? ''} ${page.title}`"
      :ref="page.id"
      tabindex="-1"
      class="page-content"
      @click="itemClicked(page)"
      v-show="Object.keys(showChildren).length === 0"
    >
      <div v-if="treePage.length > 0" class="option-margin-left flex flex-row justify-start items-start flex-nowrap break-words">
        <span class="number mr-0.5" v-if="page.key">{{ page.key }}</span>
        <span class="mx-0.5" v-if="page.key"> | </span>
        <span class="text-left ml-0.5">{{ page.title }}</span>
      </div>
      <BaseArrowRight v-if="page.subMenus" class="option-margin-right" />
    </div>
    <BaseTreePage
      v-if="page.subMenus && showChildren[page.id]"
      :treePage="page.subMenus"
      :treeGroup="page"
      :title="page.title"
      :isTopLevel="false"
      :nav-context="navContext"
      @goBack="childBack"
      @itemSelected="childItemSelected"
      @groupSelected="childGroupSelected"
      @navGoBack="childNavGoBack"
    />
  </div>
</template>

<script>
import BaseArrowRight from "../SVG-Icons/BaseArrowRight";
import BaseBackArrow from "../SVG-Icons/BaseBackArrow";

export default {
  components: {
    BaseArrowRight,
    BaseBackArrow
  },
  emits: ["go-back", "item-selected", "group-selected", "nav-go-back"],
  props: {
    treePage: Array,
    title: String,
    isTopLevel: Boolean,
    treeGroup: Object,
    navContext: Object,
    idPrefix: Number,
    closeDropdownContext: Object,
    id: String
  },
  data() {
    return {
      showChildren: {},
      refList: []
    };
  },
  computed: {
    options() {
      return [...this.treePage];
    }
  },
  methods: {
    itemClicked(page) {
      if (page.subMenus) {
        this.showChildren[page.id] = true;
        this.$emit("group-selected", page);
      } else {
        this.$emit("item-selected", { group: this.title, item: page });
      }
    },
    logSth(sth) {
      if(process.env.NODE_ENV === "development"){
        console.log(sth);
      }
    },
    goBack() {
      this.$emit("go-back");
      this.$emit("nav-go-back", this.treeGroup);
    },
    childBack() {
      this.showChildren = {};
    },
    childItemSelected(payload) {
      this.$emit("item-selected", {
        group: this.title ?? payload.group,
        item: payload.item
      });
    },
    childGroupSelected(page) {
      this.$emit("group-selected", page);
    },
    childNavGoBack(item) {
      this.$emit("nav-go-back", item);
    },
    findElementInTreePage(id) {
      return this.treePage.find(page => page.id === id);
    },
    onNavBack(eventArgs) {
      if (this.findElementInTreePage(eventArgs.newFocusedMenu.id)) {
        this.childBack();
        document.getElementById(eventArgs.prevFocusedMenu.id).blur();
        document.getElementById(eventArgs.newFocusedMenu.id).focus();
      }
    },
    onNavNavigate(eventArgs) {
      if (this.findElementInTreePage(eventArgs.newMenuGroup.id)) {
        this.showChildren[eventArgs.newMenuGroup.id] = true;
      }
    },
    onNavSelected({ selectedMenu }) {
      if (this.findElementInTreePage(selectedMenu.id)) {
        this.itemClicked(selectedMenu);
      }
    },
    onFocusElem(focusedMenuElem){
      const focusedElemID = focusedMenuElem.id;
      // this.$refs[focusedElemID].focus();
      document.getElementById(focusedElemID).focus();
    }
  },
  watch: {
    treePage(options) {
      if(process.env.NODE_ENV === "development"){
        console.log("WATCH - treePage | options Watch", options);
        console.log("WATCH - treePage | this.treePage", this.treePage);
      }
      if (this.treePage.length === 0) {
        this.options = [];
      }
    }
  },
  mounted() {
    for (const key in this.$refs) {
      this.refList.push({ [key]: this.$refs[key], ref: key });
    }

    // const firstRef = this.$refs[this.treePage[0].id];
    // firstRef?.focus();
  },
  created() {
    this.navContext.on("nav-back", this.onNavBack);

    this.navContext.on("nav-navigate", this.onNavNavigate);

    this.navContext.on("nav-selected", this.onNavSelected);

    this.navContext.on("focus-elem", this.onFocusElem);
  },
  unmounted() {
    this.navContext.off("nav-back", this.onNavBack);

    this.navContext.off("nav-navigate", this.onNavNavigate);

    this.navContext.off("nav-selected", this.onNavSelected);

    this.navContext.off("focus-elem", this.onFocusElem);
  }
};
</script>

<style scoped>
.select-option {
  width: inherit;
  height: inherit;

  /* margin-left: 1re; */

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.page-content {
  border-top: 1px solid #acacac;
  width: inherit;
  height: inherit;
  padding: 10px 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-container {
  border-top: 1px solid #acacac;
  width: inherit;
  height: inherit;
  padding: 10px 0px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.page-content:hover,
.focused {
  background: #f6f6f6;
}

.option-margin-right {
  margin-right: 1rem;
}

.option-margin-left {
  margin-left: 1rem;
}

.number {
  color: #2c5cfc;
}
</style>
