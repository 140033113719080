export default class RevalidationBus {
    /**
     *
     * @type Map
     */
    _subscriberCallbacks = new Map();
    get subscriberCallbacks() {
        return this._subscriberCallbacks;
    }

    set subscriberCallbacks(value) {
        this._subscriberCallbacks = value;
    }

    /**
     *
     * @param {String} key
     * @param {Function} fun
     */
    add(key, fun) {
        this._subscriberCallbacks.set(key, fun);
    }

    /**
     *
     * @param key
     */
    remove(key){
        this._subscriberCallbacks.delete(key);
    }

    /**
     *
     * @returns {Promise[]}
     */
    revalidate = () => {
        let promises = [];
        this._subscriberCallbacks.forEach((fun, key) => {
            promises.push(new Promise((resolve, reject) => {
                /** @type {import("@/types").RevalidationResult} */
                const res = fun(true);

                if(res === undefined || res == null){
                    reject({reason: "Validation Response ist undefined oder null.", key})
                }

                if(res.valid){
                    resolve();
                }else {
                    reject({reason:res.reason, key});
                }
            }))
        })
        return promises;
    }
}