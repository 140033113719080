import {isEmpty} from "lodash";
import mitt from "mitt";
import cloneDeep from "lodash.clonedeep";
import devResult from "@/store/devResult";
import {timeStampToDateString} from "@/helper/helper";
import dayjs from "dayjs";
import {getReasonFromString, getRuleFromString} from "@/helper/helper"
import {keysObj} from "@/store/devKeys"

export const appState = {
    state: () => ({
        employmentCtx: mitt(),
        birthdateCtx: mitt(),
        supplyBeginCtx: mitt(),
        ruleCtx: mitt(),
        reasonCtx: mitt(),
        validateSupplyBeginCtx: mitt(),
        //Birthdate of the user, user input
        birthdate: null,
        //Source of reasons of retirement to display on the screen
        retirementReasons: [
            {
                id: 1,
                text: "Erreichen der Altersgrenze",
                isSelected: false
            },
            {
                id: 2,
                text: "Auf Antrag",
                isSelected: false
            },
            {
                id: 3,
                text: "Dienstunfähigkeit",
                isSelected: false
            },
            {
                id: 4,
                text: "Schwerbehinderung",
                isSelected: false
            },
            {
                id: 5,
                text: "Einstweiliger Ruhestand",
                isSelected: false
            }
        ],
        //Beginn of the supply, user input
        supplyBegin: null,
        //Rules which apply to the retirement, used for display
        rules: [
            {
                id: 1,
                text: "Verwaltungsdienst",
                icon: "icon-verwaltungsdienst",
                isSelected: false
            },
            {
                id: 2,
                text: "Feuerwehr im Einsatzdienst",
                icon: "icon-feuerwehr-im-einsatzdienst",
                isSelected: false
            },
            {
                id: 3,
                text: "Technischer Aufsichtsdienst",
                subText: "in untertägigen Bergwerksbetrieben",
                icon: "icon-tech-aufsichtsbeamte",
                isSelected: false
            },
            {
                id: 4,
                text: "Lehrkräfte",
                icon: "icon-lehrkraefte",
                isSelected: false
            },
            {
                id: 5,
                text: "Professorenschaft",
                subText: "Universität",
                icon: "icon-hochschullehrer",
                isSelected: false
            },
            {
                id: 6,
                text: "Professorenschaft",
                subText: "Fachhochschule",
                icon: "icon-hochschullehrer",
                isSelected: false
            },
            {
                id: 7,
                text: "Polizeivollzugsdienst",
                icon: "icon-polizeivollzugsdienst",
                isSelected: false
            },
            {
                id: 8,
                text: "Polizeivollzugsdienst",
                subText: "mit mind 25 Jahren Wechselschicht",
                icon: "icon-polizeivollzugsdienst",
                isSelected: false
            },
            {
                id: 9,
                text: "Justizvollzugsdienst",
                icon: "icon-justizvollzugsdienst",
                isSelected: false
            }
        ],
        workingHours: {
            employment: null
        },
        //Contains the options for The employment types from the WorkTimes page.
        /** @type {import("../types").Key[]} */
        optionsMenus: [],
        //Contains the selected wokTime objects
        /**
         * @type{import('@/types').Period[]}
         */
        servicePeriods: /** @type{import('@/types').Period[]} */[],
        //The actual payload send to the backend
        result: {},
        gesAltersgrenze: null
    }),
    getters: {
        getBirthdate(state) {
            return state.birthdate;
        },
        getReasons(state) {
            return state.retirementReasons;
        },
        getSelectedReason(state) {
            return state.retirementReasons.find(reason => reason.isSelected === true);
        },
        getBegin(state) {
            return state.supplyBegin;
        },
        getRules(state) {
            return state.rules;
        },
        getSelectedRule(state) {
            return state.rules.find(rule => rule.isSelected === true)
        },
        getWorkingHours(state) {
            return state.periods;
        },
        getOptionMenus(state) {
            return state.optionsMenus;
        },
        getEmployment(state) {
            return state.workingHours.employment;
        },
        /**
         *
         * @param state
         * @return {import("@/types").Period[]}
         */
        getServicePeriods(state) {
            return state.servicePeriods;
        },
        getResult(state) {
            return state.result;
        },
        getEmploymentCtx(state) {
            return state.employmentCtx;
        },
        getBirthdateCtx(state) {
            return state.birthdateCtx;
        },
        getSupplyBeginCtx(state) {
            return state.supplyBeginCtx;
        },
        getRuleCtx(state) {
            return state.ruleCtx;
        },
        getReasonCtx(state) {
            return state.reasonCtx;
        },
        getValidateSupplyBeginCtx(state) {
            return state.validateSupplyBeginCtx;
        },
        searchForPeriodRowNum(state) {
            return rowId => {
                let cnt = 0;
                for (const period of state.servicePeriods) {
                    if (period.id === rowId) return cnt;
                    cnt++
                }
            }
        },
        getGesAltergrenze(state) {
            // console.log("getGesAltergrenze | gesAltersgrenze: ", state.gesAltersgrenze);
            return state.gesAltersgrenze;
        }
    },
    mutations: {
        updateBirthdate(state, birthdate) {
            state.birthdate = birthdate;
            state.birthdateCtx.emit("set", dayjs(birthdate));
            // state.validateSupplyBeginCtx.emit("state-change");
        },
        setReasonSelected(state, choosedReason) {
            const currSelReason = state.retirementReasons.find(reason => reason.isSelected === true);

            if (currSelReason) currSelReason.isSelected = false;
            choosedReason.isSelected = true;
            if (process.env.NODE_ENV === "development") {
                console.debug("setReasonSelected | ", currSelReason?.text || "_", " selected ", currSelReason?.isSelected, " || ", choosedReason?.text || "", " selected ", choosedReason?.isSelected);
                console.info("setReasonSelected | changed from ", currSelReason?.text || "_", " to ", choosedReason?.text || "_");
            }
            state.reasonCtx.emit("set", dayjs(state.birthdate));

        },
        updateBegin(state, supplyBegin) {
            state.supplyBegin = supplyBegin;
            state.supplyBeginCtx.emit("set", dayjs(state.birthdate));
            if (process.env.NODE_ENV === "development") {
                console.info("updateBegin | update supplyBegin with ", supplyBegin);
            }
        },
        setRuleSelected(state, choosedRule) {
            const currSelRule = state.rules.find(reason => reason.isSelected === true);

            if (currSelRule) currSelRule.isSelected = false;
            choosedRule.isSelected = true;

            if (process.env.NODE_ENV === "development") {
                console.debug("setReasonSelected | ", currSelRule?.text || "_", " selected ", currSelRule?.isSelected, " || ", choosedRule?.text || "", " selected ", choosedRule?.isSelected);
                console.info("setReasonSelected | changed from ", currSelRule?.text || "_", " to ", choosedRule?.text || "_");
            }

            state.ruleCtx.emit("set");
            // state.validateSupplyBeginCtx.emit("state-change");
        },
        setWorkingHours(state, payload) {
            state.periods = payload.periods;
        },
        //############ Mutations for managing the service period (Dienszeiten) state ############
        insertPeriodRow(state, row) {
            state.servicePeriods.push(row);
        },
        removePeriodRow(state, payload) {
            const row = payload.row;

            const deletedPeriod = state.servicePeriods.find(serviceRow => serviceRow.id === row.id);
            const delPeriodArrPos = state.servicePeriods.findIndex(period => period.id === deletedPeriod.id);
            state.servicePeriods.forEach(period => {
                if (period.idx > delPeriodArrPos) period.idx--;
            })

            state.servicePeriods = state.servicePeriods.filter(serviceRow => serviceRow.id !== row.id);

            if (payload.reset) {
                const newRow = {id: row.id, idx: row.idx};
                state.servicePeriods.push(newRow);
            }
        },
        savePeriod(state, payload) {
            const periodRow = state.servicePeriods.find(workPeriod => workPeriod.id === payload.rowId);

            if (!periodRow) {
                if (process.env.NODE_ENV === "development") {
                    console.log("Store | savePeriod => period not found!");
                }
                return;
            }
            if (!periodRow.workPeriod) periodRow.workPeriod = {};

            if (isNaN(payload.timestamp) || payload.timestamp === null) {
                if (payload.objKey === "from") periodRow.workPeriod.from = null;
                if (payload.objKey === "to") periodRow.workPeriod.to = null;
                return;
            }

            if (payload.timestamp && payload.objKey === "from") {
                periodRow.workPeriod.from = payload.timestamp;
            } else if (payload.timestamp && payload.objKey === "to") {
                periodRow.workPeriod.to = payload.timestamp;
            }
        },
        updateWorkPeriod(state, {periodId, workPeriod}) {
            const period = state.servicePeriods.find(period => period.id === periodId);
            period.workPeriod = workPeriod;
        },
        insertIndex(state, {periodId, index}) {
            const period = state.servicePeriods.find(period => period.id === periodId);
            period.idx = index;
        },
        /**
         *
         * @param state
         * @param {{rowId: number, employment: String, itemId: String}} payload
         */
        setEmployment(state, payload) {
            /** @type {import("../types").Period} */
            const periodRow = state.servicePeriods.find(period => period.id === payload.rowId);
            // periodRow.employment = payload.employment;
            periodRow.employment = {
                id: payload.itemId,
                text: payload.employment
            }
            state.employmentCtx.emit("key-added");
        },
        /**
         *
         * @param state
         * @param {number} rowId
         */
        removeEmployment(state, rowId) {
            const periodRow = state.servicePeriods.find(period => period.id === rowId);
            periodRow.employment = null;
        },
        setTimeFracture(state, payload) {
            //TODO Wenn Fracture einen NAN wert beinhaltet fehler anzeigen!
            const periodRow = state.servicePeriods.find(period => period.id === payload.rowId);

            if (!periodRow.fracture) periodRow.fracture = {};

            if (payload.value === 0) {
                periodRow.fracture = null;
                return;
            }

            if (payload.objKey === "counter") {
                periodRow.fracture.counter = payload.value;
            } else if (payload.objKey === "denominator") {
                periodRow.fracture.denominator = payload.value;
            }
        },
        setEligible(state, payload) {
            const periodRow = state.servicePeriods.find(period => period.id === payload.rowId);

            if (!periodRow.eligible)
                periodRow.eligible = {days: NaN, years: NaN};

            if (payload.objKey === "years") {
                periodRow.eligible.years = payload.value;
            } else if (payload.objKey === "days") {
                periodRow.eligible.days = payload.value;
            }
        },
        //############ Mutations to set the result of the backend calculation
        setResult(state, result) {
            state.result = result;
        },
        setGesAltersgrenze(state, gesAltersgrenze) {
            // console.log("setGesAltersgrenze | gesAltersgrenze: ", gesAltersgrenze);
            state.gesAltersgrenze = gesAltersgrenze;
        },
        /**
         *
         * @param {Object} state
         * @param {import("../types").EmploymentKeys} employmentKeys
         */
        setEmploymentKeys(state, employmentKeys) {
            state.optionsMenus = employmentKeys.keys;
            console.log("AppState | setEmploymentKeys ", state.optionsMenus);
        }
    },
    actions: {
        setBirthdate(context, payload) {
            context.commit("updateBirthdate", payload.timestamp);
        },
        selectReason(context, payload) {
            context.commit("setReasonSelected", payload.value);
        },
        setBegin(conext, payload) {
            conext.commit("updateBegin", payload.timestamp);
        },
        selectRule(conext, payload) {
            conext.commit("setRuleSelected", payload.value);
        },
        setWorkingHours(conext, payload) {
            conext.commit("updatePeriods", payload);
        },
        //############ Actions for managing the service period (Dienszeiten) state ############
        generatePeriodRow(context, payload) {
            let row = {};
            row.id = payload.rowId;
            context.commit("insertPeriodRow", row);
        },
        removePeriodRow(context, payload) {
            context.commit("removePeriodRow", payload);
        },
        savePeriod(context, payload) {
            context.commit("savePeriod", {
                rowId: payload.rowId,
                timestamp: payload.timestamp,
                objKey: payload.objKey
            });
        },
        updatePeriod(context, payload) {
            switch (payload.type) {
                case "workPeriod":
                    context.commit("updateWorkPeriod", {periodId: payload.id, workPeriod: payload.value});
                    break;
                case "idx":
                    context.commit("insertIndex", {periodId: payload.rowId, index: payload.idx})
                    break;
            }
        },
        /**
         *
         * @param context
         * @param {{rowId: number, employment: String, itemId: String}} payload
         */
        setEmployment(context, payload) {
            context.commit("setEmployment", {
                rowId: payload.rowId,
                employment: payload.employment,
                itemId: payload.itemId
            });
        },
        /**
         *
         * @param context
         * @param {number} rowId
         */
        removeEmployment(context, rowId) {
            if (context.getters.getServicePeriods.find(period => period.id === rowId)) {
                context.commit("removeEmployment", rowId);
            }
        },
        setTimeFracture(context, payload) {
            context.commit("setTimeFracture", payload);
        },
        setEligible(context, payload) {
            context.commit("setEligible", payload);
        },
        /**
         * This Method will validate if the to date of an period is after the from date.
         * @param {Object} context The store context.
         * @param {Number} rowID The id of the DynamicRow which should be validated
         * @param {Function} callback Callback to which a boolean varaible containing the the validitiy will be passed.
         */
        validatePeriodRow(context, {rowID, callback}) {
            let isValid = true;

            const servicePeriod = context.state.servicePeriods.find(el => el.id === rowID);

            if (!servicePeriod) {
                if (process.env.NODE_ENV === "development") {
                    console.log("Store | validatePeriodRow => servicePeriod not found")
                }
                return;
            }

            if (servicePeriod.workPeriod === undefined || isEmpty(servicePeriod.workPeriod)) {
                callback(isValid);
                return;
            }

            const fromTimestamp = servicePeriod.workPeriod.from;
            const toTimestamp = servicePeriod.workPeriod.to;

            if (fromTimestamp === undefined || toTimestamp === undefined) {
                callback(isValid);
                return;
            }
            if (fromTimestamp === null || toTimestamp === null) {
                callback(isValid);
                return;
            }

            if (toTimestamp <= fromTimestamp) isValid = false;

            callback(isValid);
        },
        validateGeneral(context, callback) {
            let isValid = true;
            const birthdate = new Date(context.state.birthdate);
            const reason = context.state.retirementReasons.find(reason => reason.isSelected === true);
            const begin = context.state.supplyBegin;

            //####################################################
            //Validate birthdate
            if (!birthdate) isValid = false;
            //####################################################
            //Validate reason
            if (!reason) isValid = false;
            //####################################################
            //Validate begin
            if (!begin) isValid = false;
            //####################################################

            callback(isValid);
        },
        /**
         * This method will validate all user inputs and calls the callback with an boolan value whether the input is valid or not
         * The callback will called with true if all is valid and fals if one test fails.
         * @param {Object} context The store context.
         * @param {Function} callback Callback to which a boolean varaible containing the the validitiy will be passed.
         */
        validateState(context, callback) {
            let isValid = true;
            const birthdate = new Date(context.state.birthdate);
            const reason = context.state.retirementReasons.find(reason => reason.isSelected === true);
            const begin = context.state.supplyBegin;
            const servicePeriods = context.state.servicePeriods;

            //####################################################
            //Validate birthdate
            if (!birthdate) isValid = false;
            //####################################################
            //Validate reason
            if (!reason) isValid = false;
            //####################################################
            //Validate begin
            if (!begin) isValid = false;
            //####################################################
            //Validate servicePeriod
            for (const servicePeriod of servicePeriods) {
                // if (!servicePeriod.eligible?.years || !servicePeriod.eligible?.days) {
                //   isValid = false;
                //   break;
                // }

                if (!servicePeriod.employment) {
                    isValid = false;
                    break;
                }

                // if (
                //   !servicePeriod.fracture?.counter ||
                //   !servicePeriod.fracture?.denominator
                // ) {
                //   isValid = false;
                //   break;
                // }

                if (!servicePeriod.workPeriod?.from || !servicePeriod.workPeriod?.to) {
                    isValid = false;
                    break;
                }
            }

            // if (!isValid) context.commit("setResult", { error: "inputs invalid" });
            callback(isValid);
        },
        /**
         * This method sends the data to the backend to calculate. The response will be saved in the result key from the store.
         * @param {Object} context The store context
         * @param {Object} router The vue router object
         */
        async sendRequest(context, router) {
            try {
                let tempPeriods = cloneDeep(context.state.servicePeriods);

                tempPeriods.forEach(/** @type{import("../types").Period} */period => {
                    period.employment = period.employment?.id;

                    // const fromDate = new Date(period.workPeriod.from);
                    // period.workPeriod.from = Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
                    //
                    // const toDate = new Date(period.workPeriod.to);
                    // period.workPeriod.to = Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());
                });

                const birthdateDate = new Date(context.state.birthdate);
                const supplyBeginnDate = new Date(context.state.supplyBegin);
                const selectedRule = context.state.rules.find(rule => rule.isSelected === true);

                const payload = {
                    birthdate: `${birthdateDate.getDate()}.${birthdateDate.getMonth() + 1}.${birthdateDate.getFullYear()}`,
                    reason: getReasonFromString(
                        context.state.retirementReasons.find(reason => reason.isSelected === true).text
                    ),
                    supplyBeginn: `${supplyBeginnDate.getDate()}.${supplyBeginnDate.getMonth() + 1}.${supplyBeginnDate.getFullYear()}`,
                    rule: getRuleFromString(selectedRule?.subText ? selectedRule?.text + " " + selectedRule?.subText : selectedRule?.text),
                    timeFrames: tempPeriods.map(/** @type {import("../types").Period} */period => {
                        const fromDate = new Date(period.workPeriod.from);
                        const toDate = new Date(period.workPeriod.to);

                        const yearStrFrom = fromDate.getFullYear();
                        const monthStrFrom = fromDate.getMonth() + 1;
                        const dayStrFrom = fromDate.getDate();

                        const fromDateStr = `${dayStrFrom}.${monthStrFrom}.${yearStrFrom}`;

                        const yearStrTo = toDate.getFullYear();
                        const monthStrTo = toDate.getMonth() + 1;
                        const dayStrTo = toDate.getDate();

                        const toDateStr = `${dayStrTo}.${monthStrTo}.${yearStrTo}`;

                        /** @type {import("../types").Period} */
                        const reqPeriod = cloneDeep(period);
                        reqPeriod.workPeriod.from = fromDateStr;
                        reqPeriod.workPeriod.to = toDateStr;

                        if (period.eligible && (period.eligible.days === null || period.eligible.days === undefined || isNaN(period.eligible.days)
                            || period.eligible.years === null || period.eligible.years === undefined || isNaN(period.eligible.years))) {
                            reqPeriod.eligible = null;
                        }

                        return reqPeriod;
                    })
                };

                //1. Mögliche Lösung relative url
                //"/versorgungsrechner/rest/calculate"
                if (process.env.NODE_ENV === "production") {
                    const response = await fetch(window.location.href.replace("/timeline", "/rest/calculate"), {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(payload)
                    });
                    const responseData = await response.json();
                    context.commit("setResult", responseData);
                } else if (process.env.NODE_ENV === "development") {
                    context.commit("setResult", devResult)
                }
            } catch (e) {
                context.commit("setResult", {error: e});
                if (process.env.NODE_ENV === "development") {
                    console.log("Fetch Error: ", e);
                }
            }
            router.push("/result");
        },
        async getCalcVars(context, cb) {
            let tempPeriods = cloneDeep(context.state.servicePeriods);
            tempPeriods.forEach(period => {
                period.employment = period.employment.text.split(' ')[0]
            });

            const selectedRule = context.state.rules.find(rule => rule.isSelected === true);
            const payload = {
                birthdate: context.state.birthdate,
                reason: getReasonFromString(
                    context.state.retirementReasons.find(reason => reason.isSelected === true).text
                ),
                supplyBeginn: context.state.supplyBegin,
                rule: getRuleFromString(selectedRule?.subText ? selectedRule?.text + " " + selectedRule?.subText : selectedRule?.text),
                timeFrames: tempPeriods
            };

            cb(payload)
        },

        /**
         *
         * @param {import("vuex").ActionContext} context
         * @param {(valid: boolean, error: boolean) => void} cb
         */
        async getGestzlAltersGr(context, cb) {
            // console.log("getGestzlAltersGr | Called")

            if (process.env.NODE_ENV === "development") {
                console.log("getGestzlAltersGr | Called")
            }
            if (!context.getters.getBirthdate || !context.getters.getBegin || !context.getters.getSelectedReason || !context.getters.getSelectedRule) {
                if (process.env.NODE_ENV === "development") {
                    console.log("getGestzlAltersGr | Birthdate: ", context.getters.getBirthdate, " || supplyBegin: ", context.getters.getBegin, " || Rule: ", context.getters.getSelectedRule?.text, " || Reason: ", context.getters.getSelectedReason?.text);
                    console.log("getGestzlAltersGr | return");
                }

                cb(false, true);
                return;
            }

            if (getReasonFromString(context.getters.getSelectedReason?.text) !== "N") {
                if (process.env.NODE_ENV === "development") {
                    console.log("getGestzlAltersGr | Reason != Erreichen der Altersgrenze")
                    console.log("getGestzlAltersGr | return");
                }

                cb(false, true);
                return;
            }
            if (process.env.NODE_ENV === "development") {
                console.log("getGestzlAltersGr | Birthdate: ", context.getters.getBirthdate, " || supplyBegin: ", context.getters.getBegin, " || Rule: ", context.getters.getSelectedRule?.text, " || Reason: ", context.getters.getSelectedReason?.text);
            }

            if (process.env.NODE_ENV === "development") {
                console.log("Request Obj:", {
                    gebDate: timeStampToDateString(context.getters.getBirthdate),
                    supplyDate: timeStampToDateString(context.getters.getBegin),
                    rule: context.getters.getSelectedRule?.subText ?
                        getRuleFromString(context.getters.getSelectedRule?.text + " " + context.getters.getSelectedRule?.subText) :
                        getRuleFromString(context.getters.getSelectedRule?.text)
                })
            }
            const response = await fetch(window.location.href.replace("general", "rest/validate"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    gebDate: timeStampToDateString(context.getters.getBirthdate),
                    supplyDate: timeStampToDateString(context.getters.getBegin),
                    rule: context.getters.getSelectedRule?.subText ?
                        getRuleFromString(context.getters.getSelectedRule?.text + " " + context.getters.getSelectedRule?.subText) :
                        getRuleFromString(context.getters.getSelectedRule?.text)
                })
            });

            try {
                const resObj = await response.json();
                const valid = resObj.valid;
                if (process.env.NODE_ENV === "development") {
                    console.log("getGestzlAltersGr | ", resObj.gesetlzlicheAltersgrenze)
                }
                context.commit("setGesAltersgrenze", resObj.gesetlzlicheAltersgrenze);
                if (process.env.NODE_ENV === "development") {
                    console.log("getGestzlAltersGr | Valitidy Response", valid);
                }

                cb(valid, false);
            } catch (e) {
                console.error(e)
                console.error("response", response.status)
                cb(false, true)
            }
        },
        /**
         *
         * @param {import("vue").AppContext} context
         */
        fetchEmploymentKeys(context) {
            const options = {method: 'GET'};

            if (process.env.NODE_ENV === "development") {
                context.commit("setEmploymentKeys", keysObj)
                return;
            }

            fetch(window.location.href.replace("general", "rest/keys"), options)
                .then(response => response.json())
                .then(response => {
                    console.log("AppSate | fetchEmploymentKeys", response);
                    return response;
                })
                .then(response => context.commit("setEmploymentKeys", response))
                // .then(response => console.log(response))
                .catch(err => console.error(err));
        }
    }
}
