<template>
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.855 1.91183L5.46875 10.7136L0.144958 5.72135L1.85503 3.89772L5.46875 7.2864L13.145 0.0881958L14.855 1.91183Z"
      fill="white"
    />
  </svg>
</template>
