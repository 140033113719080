<template>
  <form role="form">
    <input
      :data-test="testLabelDay"
      ref="birthDay"
      role="textbox"
      aria-label="Tag Eingabe"
      aria-placeholder="Tag"
      :class="{ invalid: dayInvalid }"
      class="row-item"
      type="text"
      :placeholder="placeholder[0]"
      :disabled="disabledInpts[0]"
      maxlength="2"
      tabindex="0"
      v-model="inputValueDay"
      @input="onInputDay()"
      @blur="onBlurDay"
    />
    <input
      :data-test="testLabelMonth"
      ref="birthMonth"
      role="textbox"
      aria-label="Monat Eingabe"
      aria-placeholder="Monat"
      :class="{ invalid: monthInvalid }"
      class="row-item"
      type="text"
      :placeholder="placeholder[1]"
      :disabled="disabledInpts[1]"
      maxlength="2"
      tabindex="0"
      v-model="inputValueMonth"
      @input="onInputMonth()"
    />
    <input
      :data-test="testLabelYear"
      ref="birthYear"
      role="textbox"
      aria-label="Jahr Eingabe"
      aria-placeholder="Jahr"
      :class="{ invalid: yearInvalid }"
      class="row-item"
      type="text"
      :placeholder="placeholder[2]"
      :disabled="disabledInpts[2]"
      maxlength="4"
      tabindex="0"
      v-model="inputValueYear"
      @input="onInputYear()"
      @blur="onBlurYear"
    />
  </form>
</template>

<script>
import {checkIfLeapYear, getReasonFromString} from "@/helper/helper";
import dayjs from "dayjs";
export default {
  emits: ["dateConstructed", "input-invalid", "errStateChange"],
  props: {
    compKey: String,
    contextStr: String,
    classes: String,
    placeholder: Array,
    disabledInpts: Array,
    initValues: Array,
    tabIndexe: Array,
    initialFocus: Boolean,
    resetCtx: Object
  },
  data() {
    return {
      inputValueDay: "",
      inputValueMonth: "",
      inputValueYear: "",
      dayInvalid: false,
      monthInvalid: false,
      yearInvalid: false,
      checkForChar: /\D/,
      leastValDate: new Date(1950, 0, 1)
    };
  },
  computed:{
    testLabelDay(){
      return `${this.contextStr}-input-day`
    },
    testLabelMonth(){
      return `${this.contextStr}-input-month`
    },
    testLabelYear(){
      return `${this.contextStr}-input-year`
    }
  },
  methods: {
    onInput(){
      this.resetCtx.emit("reset", this.compKey);

      this.revalidate();
    },
    onBlurDay(){
      this.dayInvalid = false;
      this.validateDay();
      this.checkDayForZero();
    },
    checkDayForZero() {
      const dayNumVal = parseInt(this.inputValueDay);
      if (isNaN(dayNumVal) || dayNumVal === null)
        return;

      if (dayNumVal === 0) {
        this.$emit("input-invalid", {
          key: this.compKey,
          action: "set",
          type: "day-zero",
          msg: "Für den Tag sind nur Eingaben größer 0 erlaubt."
        });

        this.dayInvalid = true;
      } else {
        this.$emit("input-invalid", {key: this.typeString, action: "reset", type: "day"});
      }
    },
    validateDay() {
      if(this.inputValueDay === "") {
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "day"});
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "day-feb"});
        this.dayInvalid = false;
        return;
      }

      this.dayInvalid = this.checkForChar.test(this.inputValueDay);
      if(this.dayInvalid){
        this.$emit("input-invalid", { key: this.compKey, action: "set", type: "day", msg: `Für einen Tag sind nur Zahlen von 1 bis 31 bzw. im Februar 28 (29 für ein Schaltjahr) zugelassen.`});
      }

      //Hier wird das mit dem Letzen tag im Monat erstellt.
      //z.B: new Date(2020, 1, 0) wird den 31 Januar 2020 zurückgeben.
      const compDate = new Date(
          parseInt(this.inputValueYear),
          parseInt(this.inputValueMonth),
          0
      );

      const numVal = parseInt(this.inputValueDay);

      if (this.dayInvalid) {
        const newVal = numVal.toString();
        this.inputValueDay = isNaN(numVal) ? "" : newVal;
      }

      if (!this.dayInvalid) {
        const numInp = parseInt(this.inputValueDay);
        if(!isNaN(compDate.getTime()) && numInp > compDate.getDate()) {
          this.dayInvalid = true;
        }
        else if (numInp < 1 || numInp > 31) {
          this.dayInvalid = true;
        }
      }

      if(!isNaN(compDate.getTime()) && parseInt(this.inputValueMonth) <= 12 && numVal > compDate.getDate()){
        this.$emit("input-invalid", { key: this.compKey, action: "set", type: "day", msg: `Der gewählte Monat hat nur ${compDate.getDate()} Tage.`});
        this.$emit("errStateChange", {hasErr: true});
        this.dayInvalid = true;
      }else if(!isNaN(compDate.getTime()) && this.inputValueMonth !== "" && numVal > compDate.getDate()){
        this.$emit("input-invalid", { key: this.compKey, action: "set", type: "day", msg: "Für einen Tag sind nur Zahlen von 1 bis 31 bzw. im Februar 28 (29 für ein Schaltjahr) zugelassen." });
        this.$emit("errStateChange", {hasErr: true});
        this.dayInvalid = true;
      }

      if (!this.dayInvalid) {
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "day"});
        this.$emit("errStateChange", {hasErr: false});
        this.constructDate();
      }
    },
    validateMonth() {
      if(this.inputValueMonth === "") {
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "month" });
        this.monthInvalid = false;
        return;
      }

      this.monthInvalid = this.checkForChar.test(this.inputValueMonth);

      if (this.monthInvalid) {
        const numVal = parseInt(this.inputValueMonth);
        const newVal = numVal.toString();
        this.inputValueMonth = isNaN(numVal) ? "" : newVal;
      }

      if (!this.monthInvalid) {
        const numInp = parseInt(this.inputValueMonth);
        if (numInp < 1 || numInp > 12) this.monthInvalid = true;
      }

      if (!this.monthInvalid) {
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "month"});
        this.$emit("errStateChange", {hasErr: false});
        this.constructDate();
        return;
      }
      this.$emit("input-invalid", { key: this.compKey, action: "set", type: "month"});
      this.$emit("errStateChange", {hasErr: true});
    },
    validateYear() {
      if(this.inputValueYear === "") {
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "year" });
        this.yearInvalid = false;
        return;
      }

      const leastvalidYear = 1950;

      this.yearInvalid = this.checkForChar.test(this.inputValueYear);
      if (this.yearInvalid) {
        const numVal = parseInt(this.inputValueYear);
        const newVal = numVal.toString();
        this.inputValueYear = isNaN(numVal) ? "" : newVal;
        this.$emit("input-invalid", { key: this.compKey, action: "set", type: "year", msg: "Für ein Jahr sind nur Zahlen ab 1950 zugelassen."});
        this.yearInvalid = true;
        return;
      }

      if(this.inputValueYear.length <= 3) {
        return;
      }



      //Wenn Year valid do more checks
      if (!this.yearInvalid) {
        const numInp = parseInt(this.inputValueYear);
        if (numInp < leastvalidYear) {
          this.yearInvalid = true;
        }
      }

      if (!this.yearInvalid) {
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "year" });
        this.$emit("errStateChange", {hasErr: false});
        this.constructDate();
        return;
      }
      this.$emit("input-invalid", { key: this.compKey, action: "set", type: "year", msg: "Für ein Jahr sind nur Zahlen ab 1950 zugelassen."});
      this.$emit("errStateChange", {hasErr: true});
    },
    validateFebDay() {
      const day = this.inputValueDay;
      const month = this.inputValueMonth;
      const year = this.inputValueYear;      

      //########################################
      //Checks for Day, Month and Year
      if (!day || !month || !year) {
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "day-feb" });
        this.$emit("errStateChange", {hasErr: false});
        return;
      }

      const monthNum = parseInt(month);

      if(monthNum === 2 && checkIfLeapYear(parseInt(year))){
        if(parseInt(day) > 29){
          this.$emit("input-invalid", { key: this.compKey, action: "set", type: "day-feb" });
          this.$emit("errStateChange", {hasErr: true});
          return;
        }
      } else if(monthNum === 2) {
        if(parseInt(day) > 28) {
          this.$emit("input-invalid", { key: this.compKey, action: "set", type: "day-feb" });
          this.$emit("errStateChange", {hasErr: true});
          return;
        }
      } 

      this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "day-feb" });
      this.$emit("errStateChange", {hasErr: false});
    },
    /**
     *
     * @param {dayjs.Dayjs} birthdate
     */
    isAgeValid(birthdate){
      const supplyBegin = dayjs(this.$store.getters.getBegin);
      const yearsDiff =  supplyBegin.diff(birthdate, "years");

      const reason = this.$store.getters.getSelectedReason;

      if(!reason?.text || getReasonFromString(reason.text) === "D" || isNaN(yearsDiff)){
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "isAgeValid" });
        return;
      }

      if(yearsDiff < 60 && yearsDiff > 0){
        this.$emit("input-invalid", { key: this.compKey, action: "set", type: "isAgeValid", msg: "Ein Versorgungsbeginn ist hier erst mit Vollendung des 60. Lebensjahres möglich"});
        return;
      }

      this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "isAgeValid" });
    },
    validateYearLength(){
      if(this.inputValueDay !== "" && this.inputValueMonth !== "" && this.inputValueYear.length <= 3){
        this.$emit("input-invalid", { key: this.compKey, action: "set", type: "year-toShort", msg: "Bitte geben Sie das Jahr vierstellig ein." });
        this.yearInvalid = true;
      }else {
        this.isYearLengthValid();
      }
    },
    isYearLengthValid(){
      if(this.inputValueYear.length === 4){
        this.$emit("input-invalid", { key: this.compKey, action: "reset", type: "year-toShort" });
        this.yearInvalid = false;
      }
    },
    constructDate() {
      this.validateFebDay();

      const date = new Date(
        parseInt(this.inputValueYear),
        parseInt(this.inputValueMonth) - 1,
        parseInt(this.inputValueDay)
      );

      this.$emit("date-constructed", date);
    },
    revalidate(){
      this.validateDay();
      this.validateMonth();
      this.validateYear();
    },
    onInputDay(){
      this.revalidate();
      if (this.inputValueDay.length === 2) this.$refs.birthMonth.focus();
    },
    onInputMonth(){
      this.revalidate();
      if (this.inputValueMonth.length === 2) this.$refs.birthYear.focus();
    },
    onInputYear(){
      this.isYearLengthValid();
      this.revalidate()
    },
    onBlurYear(){
      this.validateYearLength();
    }
  },
  mounted() {
    this.resetCtx.on("revalidate", src => {
      if(src !== this.compKey){
          this.revalidate();
      }
    })

    this.$store.getters.getSupplyBeginCtx.on("set", this.isAgeValid);
    this.$store.getters.getBirthdateCtx.on("set", this.isAgeValid);
    this.$store.getters.getReasonCtx.on("set", this.isAgeValid);

    let index = 0;
    if (this.initialFocus) this.$refs?.birthDay.focus();
    if (this.initValues && this.initValues.length > 0) {
      this.initValues.forEach(val => {
        if (val) {
          switch (index) {
            case 0:
              this.inputValueDay = val;
              break;
            case 1:
              this.inputValueMonth = val;
              break;
            case 2:
              this.inputValueYear = val;
              break;
            default:
              break;
          }
        }
        index++;
      });
    }
  }
};
</script>

<style scoped>
.row-item {
  margin-right: 0.5rem;
}

input:disabled {
  background-color: #f6f6f6;
  border: 1px solid #d9dada;
}

input {
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 4px;
  width: 58px;
  height: 40px;

  text-align: center;
}

.invalid {
  border: 2px solid #e30613;
}
</style>
