<template>
  <the-result role="main"></the-result>
</template>

<script>
import TheResult from "../components/FormSites/TheResult.vue";
export default {
  components: { TheResult }
};
</script>

<style></style>
