<template>
  <div role="group">
    <div class="mb-5 flex flex-row flex-wrap justify-start items-center">
      <h2 class="flex flex-row flex-wrap headline-medium mr-1">
        {{ title }}
      </h2>
      <img
          v-if="showHelpIcon"
          role="listitem"
          class="mr-1"
          src="../../../assets/icon/star.svg"
          alt="Star Icon"
      />
      <CircledQuestionMark
          v-if="showHelpIcon"
          role="navigation"
          :aria-label="hilfeLabel"
          tabindex="0"
          class="mr-1 text-large text-centered cursor-pointer"
          color-type="black"
          @click="$emit('show-help')"
          @keydown.enter="$emit('show-help')"
      />
    </div>
    <div class="cloumn-item" id="Birthdate-input">
      <base-date-input
        context-str="Birthdate"
        :comp-key="this.compKey"
        :aria-label="title + ', ' + (hint || '')"
        :placeholder="placeholder"
        :disabledInpts="disabledInpts"
        :init-values="initValues"
        :tab-indexe="tabIndexe"
        :initial-focus="initialFocus"
        :reset-ctx="resetCtx"
        @err-state-change="errStateChange"
        @input-invalid="inputIsInvalid"
        @date-constructed="$emit('date-constructed', $event)"
      ></base-date-input>
      <p
        role="status"
        class="text-medium-medium text-cc-red-600"
        v-if="dayInvalid"
      >
        {{dayError?.errMsg || ""}}
      </p>
      <p
        role="status"
        class="text-medium-medium text-cc-red-600"
        v-if="dayInvalid"
      >
        {{dayErrorZero?.errMsg || ""}}
      </p>
      <p
        role="status"
        class="text-medium-medium text-cc-red-600"
        v-if="dayFebInvalid"
      >
        {{dayFebError?.errMsg || ""}}
      </p>
      <p
        role="status"
        class="text-medium-medium text-cc-red-600"
        v-if="monthInvalid"
      >
        {{monthError?.errMsg || ""}}
      </p>
      <p
        role="status"
        class="text-medium-medium text-cc-red-600"
        v-if="yearInvalid"
      >
        {{yearError?.errMsg || ""}}
      </p>
      <p
          role="status"
          class="text-medium-medium text-cc-red-600"
          v-if="supplyBeginInvalid && elementType === 'supplyBegin'"
      >
        {{supplyBeginError?.errMsg || ""}}<br>
        Gesetzliche Altersgrenze: Mit Ablauf des {{getGesAltersgrenze}}
      </p>
      <p
          role="status"
          class="text-medium-medium text-cc-red-600"
          v-if="ageIsInvalid"
      >
        {{ageIsInvalidError.errMsg}}
      </p>
      <p
          role="status"
          class="text-medium-medium text-cc-red-600"
          v-if="yearToShort"
      >
        {{yearToShortErr.errMsg}}
      </p>
    </div>
  </div>
</template>

<script>
import BaseDateInput from "./UiElements/BaseDateInput.vue";
import CircledQuestionMark from "@/components/FormSites/GeneralFormComponents/UiElements/SVG-Icons/CircledQuestionMark";
export default {
  components: { BaseDateInput, CircledQuestionMark },
  props: {
    compKey: String,
    title: String,
    hint: String,
    placeholder: Array,
    disabledInpts: Array,
    initValues: Array,
    tabIndexe: Array,
    hilfeLabel: String,
    showHelpIcon: Boolean,
    initialFocus: Boolean,
    elementType: String,
    containedPage: String,
    resetCtx: Object
  },
  emits: ["show-help", "dateConstructed", "input-invalid", "errStateChange"],
  data() {
    return {
      dayInvalid: false,
      dayFebInvalid: false,
      monthInvalid: false,
      yearInvalid: false,
      dateInvalid: false,
      supplyBeginInvalid: false,
      ageIsInvalid: false,
      yearToShort: false,
      dayError: null,
      dayErrorZero: null,
      dayFebError: null,
      monthError: null,
      yearError: null,
      dateError: null,
      supplyBeginError: null,
      ageIsInvalidError: null,
      yearToShortErr: null
    };
  },
  computed: {
    getGesAltersgrenze(){
      // console.log("InputComponent | getGesAltersgrenze | ", this.$store.getters.getGesAltergrenze)
      return this.$store.getters.getGesAltergrenze;
    }
  },
  methods: {
    errStateChange({ hasErr }){
      this.$emit("errStateChange", { hasErr, component: this.compKey });
      // this.$store.dispatch("setGeneralPageErr", hasErr);
    },
    inputIsInvalid(payload) {
      if(process.env.NODE_ENV === "development"){
        console.log("inputIsInvalid | BEFORE " + payload.type + " " + payload.action);
        console.log("inputIsInvalid | getGeneralPageErrs: ", this.$store.getters.getGeneralPageErrs);
        console.log("inputIsInvalid | this.supplyBeginError: ", this.supplyBeginError);
        console.log("inputIsInvalid | this.supplyBeginInvalid: ", this.supplyBeginInvalid);
      }

      if (payload.action === "set") {
        switch (payload.type) {
          case "day":
            this.dayInvalid = true;
            this.dayError = {
              id: `day-error-${payload.key}`,
              type: "addError",
              elem: this.elementType || "",
              errMsg: payload.msg,
              errType: "dayInvalid",
              page: this.containedPage || ""
            }
            this.$store.dispatch(this.dayError);
            break;
          case "day-zero":
            this.dayInvalid = true;
            this.dayErrorZero = {
              id: `day-error-${payload.key}`,
              type: "addError",
              elem: this.elementType || "",
              errMsg: payload.msg,
              errType: "dayInvalidZero",
              page: this.containedPage || ""
            }
            this.$store.dispatch(this.dayError);
            break;
          case "day-feb":
            this.dayFebInvalid = true;
            this.dayFebError = {
              id: `day-feb-${payload.key}`,
              type: "addError",
              elem: this.elementType || "",
              errMsg: "Im Februar sind für einen Tag nur Zahlen bis 28 (29 für ein Schaltjahr) zugelassen.",
              errType: "dayInvalid",
              page: this.containedPage || ""
            }
            this.$store.dispatch(this.dayFebError);
            break;
          case "month":
            this.monthInvalid = true;
            this.monthError = {
              id: `month-error-${payload.key}`,
              type: "addError",
              elem: this.elementType || "",
              errMsg: "Für einen Monat sind nur Zahlen von 1 bis 12 zugelassen.",
              errType: "monthInvalid",
              page: this.containedPage || ""
            }
            this.$store.dispatch(this.monthError);
            break;
          case "year":
            this.yearInvalid = true;
            if(!this.yearError) {
              this.yearError = {
                id: `year-error-${payload.key}`,
                type: "addError",
                elem: this.elementType || "",
                errMsg: payload.msg,
                errType: "yearInvalid",
                page: this.containedPage || ""
              };
            }
            this.$store.dispatch(this.yearError);
            break;
          case "supply":
            if(process.env.NODE_ENV === "development"){
              console.log("inputIsInvalid | set supply Error")
              console.log("inputIsInvalid | supply => called")
              console.log("inputIsInvalid | supply => !this.supplyBeginError: ", !this.supplyBeginError);
              console.log("inputIsInvalid | supply => this.elementType === supplyBegin: ", this.elementType === "supplyBegin");
            }

            this.supplyBeginInvalid = true;
            if(!this.supplyBeginError && this.elementType === "supplyBegin") {
              this.supplyBeginError = {
                type: "addError",
                id: `supply-begin-error-${payload.key}`,
                elem: this.elementType || "",
                errMsg: "Der Versorgungsbeginn darf nicht vor der gesetzlichen Altersgrenze liegen!",
                errType: "supplyBeginInvalid",
                page: this.containedPage || ""
              }
              if(process.env.NODE_ENV === "development"){
                console.log("inputIsInvalid | supply => this.supplyBeginError: ", this.supplyBeginError)
                console.log("inputIsInvalid | Supply Error set: ", this.supplyBeginError)
              }
            }
            if(this.supplyBeginError) {
              if(process.env.NODE_ENV === "development"){
                console.log("inputIsInvalid | supply => Adding the error", this.supplyBeginError);
              }
              this.$store.dispatch(this.supplyBeginError);
            }
            break;
          case "dayToHigh":
            this.dateInvalid = true;
            this.dayError = {
              id: `dayToHig-error-${payload.key}`,
              type: "addError",
              elem: this.elementType || "",
              errMsg: payload.msg,
              errType: "dayToHigh",
              page: this.containedPage || ""
            };
            this.$store.dispatch(this.dayError);
            break;
          case "isAgeValid":
            this.ageIsInvalid = true;
            this.ageIsInvalidError = {
              id: `isAgeValid-error-${payload.key}`,
              type: "addError",
              elem: this.elementType || "",
              errMsg: payload.msg,
              errType: "isAgeValid",
              page: this.containedPage || ""
            };
            this.$store.dispatch(this.ageIsInvalidError);
            break;
          case "year-toShort":
            this.yearToShort = true;
            this.yearToShortErr = {
              id: `year-toShort-error-${payload.key}`,
              type: "addError",
              elem: this.elementType || "",
              errMsg: payload.msg,
              errType: "year-toShort",
              page: this.containedPage || ""
            };
            this.$store.dispatch(this.yearToShortErr);
            break;
          default:
            break;
        }
      } else if (payload.action === "reset") {
        switch (payload.type) {
          case "day":
            this.dayInvalid = false;
            if(this.dayError) {
              this.$store.dispatch("removeGeneralPageErr", this.dayError?.id);
              this.dayError = null;
            }
            break;
          case "month":
            this.monthInvalid = false;
            if(this.monthError) {
              this.$store.dispatch("removeGeneralPageErr", this.monthError?.id);
              this.monthError = null;
            }
            break;
          case "year":
            this.yearInvalid = false;
            if(this.yearError) {
              this.$store.dispatch("removeGeneralPageErr", this.yearError?.id);
              this.yearError = null
            }
            break;
          case "day-feb":
            this.dayFebInvalid = false;
            if(this.dayFebError){
              this.$store.dispatch("removeGeneralPageErr", this.dayFebError?.id);
              this.dayError = null;
            }
            break;
          case "supply":
            if(process.env.NODE_ENV === "development"){
              console.log("inputIsInvalid | reset supplyBegin error")
            }
            this.supplyBeginInvalid = false;
            if(this.supplyBeginError && this.elementType === "supplyBegin") {
              if(process.env.NODE_ENV === "development"){
                console.log("inputIsInvalid | removing ", this?.supplyBeginError?.id, " from store")
              }
              this.$store.dispatch("removeGeneralPageErr", this?.supplyBeginError?.id);
              this.supplyBeginError = null;
            }
            break;
          case "isAgeValid":
            this.ageIsInvalid = false;
            this.$store.dispatch("removeGeneralPageErr", this.ageIsInvalidError?.id);
            this.ageIsInvalidError = null;
            break;
          case "year-toShort":
            this.yearToShort = false;
            this.$store.dispatch("removeGeneralPageErr", this.yearToShortErr?.id);
            this.yearToShortErr = null;
            break;
          default:
            break;
        }
      }
      if(process.env.NODE_ENV === "development"){
        console.log("inputIsInvalid | AFTER " + payload.type);
        console.log("inputIsInvalid | getGeneralPageErrs: ", this.$store.getters.getGeneralPageErrs);
        console.log("inputIsInvalid | this.supplyBeginError: ", this.supplyBeginError);
        console.log("inputIsInvalid | this.supplyBeginInvalid: ", this.supplyBeginInvalid);
      }
    },
    validateSupplyBegin(){
      this.$store.dispatch("getGestzlAltersGr", (valid, error) => {
        if(process.env.NODE_ENV === "development"){
          console.log("InputComponent | validateSupplyBegin => !Valid: ", !valid, " || !error: ", !error)
          console.log("InputComponent | Gesetzliche Altersgrenze: ", this.getGesAltersgrenze);
        }
        if(!valid && !error) {
          this.inputIsInvalid({action: "set", type: "supply"})
        }
        else {
          this.inputIsInvalid({action: "reset", type: "supply"});
        }
      });
    }
  },
  mounted(){
    if(this.elementType === "supplyBegin"){
      this.$store.getters.getBirthdateCtx.on("set", this.validateSupplyBegin);
      this.$store.getters.getSupplyBeginCtx.on("set", this.validateSupplyBegin);
      this.$store.getters.getReasonCtx.on("set", this.validateSupplyBegin);
      this.$store.getters.getRuleCtx.on("set", this.validateSupplyBegin);
    }
  },
  unmounted(){
    if(this.elementType === "supplyBegin"){
      this.$store.getters.getBirthdateCtx.off("set", this.validateSupplyBegin);
      this.$store.getters.getSupplyBeginCtx.off("set", this.validateSupplyBegin);
      this.$store.getters.getReasonCtx.off("set", this.validateSupplyBegin);
      this.$store.getters.getRuleCtx.off("set", this.validateSupplyBegin);
    }
  }
};
</script>

<style scoped>
.tab {
  white-space: pre;
}
</style>
