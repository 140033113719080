<template>
  <div>
    <h1 class="headline-large">Übersicht Laufbahngruppen</h1>
    <h2 class="headline-medium">Erläuterungen</h2>
    <section>
      <p>
        Die nachfolgenden Aufstellungen sollen die laufbahnrechtliche Zuordnung
        des eigenen beruflichen Werdegangs ermöglichen. Aus dieser Zuordnung
        lassen sich die entsprechenden Anforderungen, deren
        versorgungsrechtliche Bewertung und die notwendigen Eingabeschlüssel
        leichter feststellen
      </p>
    </section>
    <h2 class="headline-medium">Wichtiger Hinweis</h2>
    <section>
      <p>
        Die Aufstellungen spiegeln den
        <strong><u>Regelverlauf</u></strong> eines beruflichen Werdegangs wider.
        <strong><u>Individuelle Besonderheiten</u></strong> (z.B. Einstellung
        als "anderer Bewerber", Sonderausbildungsgänge im Schulbereich usw.)
        müssen unberüchsichtigt bleiben; ansonsten würde die Übersichtlichkeit
        verloren gehen und damit Sinn und Zweck der Aufstellungen verfehlt.
      </p>
    </section>
    <h2 class="headline-medium">Laufbahngruppen der Regellaufbahnen</h2>
    <section>
      <table border="" cellspacing="3" cellpadding="5" width="99%">
        <tbody>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Laufbahngruppe 1.1</b><br />
                <font size="2"> (ehem. einfacher Dienst)</font>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Laufbahngruppe 1.2</b><br />
                <font size="2"> (ehem. mittlerer Dienst)</font>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Laufbahngruppe 2.1</b><br />
                <font size="2"> (ehem. gehobener Dienst)</font>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Laufbahngruppe 2.2</b><br />
                <font size="2"> (ehem. höherer Dienst)<font> *)</font></font>
              </p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" height="2">
              <font size="2"></font>
              <p>
                <font size="2"
                  >(z.B. Laufbahn der allgemeinen Verwaltung,<br />
                  der Steuerverwaltung,<br />
                  des Justizwachtmeisterdienst)</font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" height="2">
              <font size="2"></font>
              <p>
                <font size="2"
                  >(z.B. Laufbahn der allgemeinen Verwaltung,<br />
                  der Steuerverwaltung,<br />
                  der KOV-Verwaltung,<br />
                  an Bibliotheken)
                </font>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" height="2">
              <font size="2"></font>
              <p>
                <font size="2"
                  >(z.B. Laufbahn der allgemeinen Verwaltung,<br />
                  der Steuerverwaltung,<br />
                  Rechtspfleger,<br />
                  an Bibliotheken)
                </font>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" height="2">
              <font size="2"
                ><p>
                  (z.B. Laufbahn der allgemeinen Verwaltung,<br />
                  der Steuerverwaltung,<br />
                  des vermessungstechn. Dienstes,<br />
                  an Bibliotheken)
                </p></font
              >
              <p>
                <font size="2"
                  ><b>*) entsprechend: </b><br />Richterinnen / Richter</font
                >
              </p>
            </td>
          </tr>

          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
          </tr>

          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b><u>Vorbereitungsdienst </u></b><br />
                im Beamtenverhältnis auf Widerruf
              </p>
              <i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0602</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b><u>Vorbereitungsdienst </u></b><br />
                im Beamtenverhältnis auf Widerruf
              </p>
              <i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0602</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b><u>Vorbereitungsdienst </u></b><br />
                im Beamtenverhältnis auf Widerruf
              </p>
              <i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0602</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b><u>Vorbereitungsdienst </u></b><br />
                im Beamtenverhältnis auf Widerruf
              </p>
              <i
                ><p align="CENTER"><b>Schlüssel 0602</b></p>
                <b> </b></i
              ><b>
                <hr />
              </b>
              <p align="CENTER">
                <b>Abgeschlossenes Hochschulstudium<br /> </b>(Erste
                Staatsprüfung)
              </p>
              <i></i>
              <p align="CENTER">
                <i><b>Schlüssel 1230</b></i>
              </p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <h2 class="headline-medium">Laufbahngruppen im Schuldienst</h2>
    <section>
      <table border="" cellspacing="3" cellpadding="5" width="99%">
        <tbody>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER"><b>Lehramt für die Primarstufe</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER"><b>Lehramt Sekundarstufe I</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER"><b>Lehramt Sekundarstufe II</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER"><b>Lehramt Sonderpädagogik</b></p>
            </td>
          </tr>

          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
          </tr>

          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><font size="2"></font></b
              ><font size="2"
                ><p align="CENTER">
                  <b
                    >ggf. Tätigkeit im öffentlichen oder nichtöffentlichen
                    Schuldienst</b
                  ><br />
                  vor der Berufung in das Beamtenverhältnis;
                </p>
                <i><b></b></i
              ></font>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><font size="2"></font></b
              ><font size="2"
                ><p align="CENTER">
                  <b
                    >ggf. Tätigkeit im öffentlichen oder nichtöffentlichen
                    Schuldienst</b
                  ><br />
                  vor der Berufung in das Beamtenverhältnis;
                </p>
                <i><b></b></i
              ></font>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><font size="2"></font></b
              ><font size="2"
                ><p align="CENTER">
                  <b
                    >ggf. Tätigkeit im öffentlichen oder nichtöffentlichen
                    Schuldienst</b
                  ><br />
                  vor der Berufung in das Beamtenverhältnis;
                </p>
                <i><b></b></i
              ></font>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><font size="2"></font></b
              ><font size="2"
                ><p align="CENTER">
                  <b
                    >ggf. Tätigkeit im öffentlichen oder nichtöffentlichen
                    Schuldienst</b
                  ><br />
                  vor der Berufung in das Beamtenverhältnis;
                </p>
                <i><b></b></i
              ></font>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b><u>Vorbereitungsdienst </u></b><br />
                im Beamtenverhältnis auf Widerruf<br />
                (2 Jahre)
              </p>
              <i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0602</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b><u>Vorbereitungsdienst </u></b><br />
                im Beamtenverhältnis auf Widerruf<br />
                (2 Jahre)
              </p>
              <i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0602</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b><u>Vorbereitungsdienst </u></b><br />
                im Beamtenverhältnis auf Widerruf<br />
                (2 Jahre)
              </p>
              <i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0602</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b><u>Vorbereitungsdienst </u></b><br />
                im Beamtenverhältnis auf Widerruf<br />
                (2 Jahre)
              </p>
              <i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0602</b></i>
              </p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Abgeschlossenes Hochschulstudium</b><br />
                <font size="2">(in der Regel 6 Semester)</font>
              </p>
              <p align="CENTER">
                <b>Prüfungszeit</b><br />
                <font size="2">(bis zu 8 Monaten)</font>
              </p>
              <i><b></b></i>
              <p align="CENTER">
                <i><b>Schlüssel 1230</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Abgeschlossenes Hochschulstudium</b><br />
                <font size="2">(in der Regel 6 Semester)</font>
              </p>
              <p align="CENTER">
                <b>Prüfungszeit</b><br />
                <font size="2">(bis zu 8 Monaten)</font>
              </p>
              <i><b></b></i>
              <p align="CENTER">
                <i><b>Schlüssel 1230</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Abgeschlossenes Hochschulstudium</b><br />
                <font size="2">(in der Regel 6 Semester)</font>
              </p>
              <p align="CENTER">
                <b>Prüfungszeit</b><br />
                <font size="2">(bis zu 8 Monaten)</font>
              </p>
              <i><b></b></i
              ><b
                ><p align="CENTER"><i>Schlüssel 1230</i></p>
                <hr />
              </b>
              <p align="CENTER">
                <b>Praktische Tätigkeit<br /></b> für das Lehramt an
                berufsbildenden Schulen <br />
                <font size="2">(bis zu 6 Monaten)</font>
              </p>
              <b><i></i></b><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 1210</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Abgeschlossenes Hochschulstudium</b><br />
                <font size="2">(in der Regel 6 Semester)</font>
              </p>
              <p align="CENTER">
                <b>Prüfungszeit</b><br />
                <font size="2">(bis zu 8 Monaten)</font>
              </p>
              <i><b></b></i>
              <p align="CENTER">
                <i><b>Schlüssel 1230</b></i>
              </p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <h2 class="headline-medium">
      Laufbahngruppen besonderer Fachrichtung einschließlich Professorinnen und
      Professoren
    </h2>
    <section>
      <table border="" cellspacing="3" cellpadding="5" width="99%">
        <tbody>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Laufbahngruppe 2.1</b><br />
                <font size="2"> (ehem. gehobener Dienst)</font>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER">
                <b>Laufbahngruppe 2.2</b><br />
                <font size="2"> (ehem. höherer Dienst)</font>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER"><b>an Fachhochschulen</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b></b>
              <p align="CENTER"><b>an Universitäten</b></p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" height="2">
              <p>
                Vgl. Anlage 2 zur Laufbahnverordnung (LVO);<br />
                <font size="2">
                  z.B. Dienst in der Sozialarbeit,<br />
                  im bergvermessungstechnischen Dienst,<br />
                  Dienst in der Datenverarbeitung</font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" height="2">
              <p>
                Vgl. Anlage 3 zur Laufbahnverordnung (LVO);<br />
                <font size="2">
                  z.B. ärztlicher Dienst,<br />
                  Dienst als Apotheker,<br />
                  Dienst in der Datenverarbeitung,<br />
                  Dienst in Gartenbau und Landschaftspflege
                </font>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" height="2">
              <font size="2"
                >soweit am 01.01.1980 übergeleitet <br />oder<br />
                nach dem 31.12.1979 berufen</font
              >
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" height="2">
              <font size="2"
                >soweit am 01.01.1980 übernommen oder übergeleitet
                <br />oder<br />
                nach dem 31.12.1979 berufen</font
              >
            </td>
          </tr>

          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u></u>
                <p align="CENTER">
                  <u>Beamtendienstzeit<br /> </u>Vollbeschäftigung:
                </p>
                <i><p align="CENTER">Schlüssel 0602</p> </i>
              </b>
              <p align="center">
                <b>
                  Teilzeitbeschäftigung, Beurlaubung, Erziehungsurlaub:
                  <font size="2"> </font></b
                ><font size="2"
                  >maßgebend sind Rechtsgrund und Zeitpunkt der Bewilligung<br />
                  <i><b></b></i
                ></font>
              </p>
              <p align="CENTER">
                <font size="2"
                  ><i><b>siehe Schlüsselkatalog</b></i></font
                >
              </p>
            </td>
          </tr>

          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">
                  Hauptberufliche Tätigkeit <br />
                  (zur anrechenbaren Dauer vgl. LVO)
                </p>
                <i><p align="CENTER">Schlüssel 1210</p></i>
                <hr />
                <p align="CENTER">Abgeschlossenes Fachhochschulstudium</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 1230</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">
                  Hauptberufliche Tätigkeit <br />
                  (zur anrechenbaren Dauer vgl. LVO)
                </p>
                <i><p align="CENTER">Schlüssel 1210</p></i>
                <hr />
                <p align="CENTER">Abgeschlossenes Hochschulstudium</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 1230</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <p align="CENTER">
                <b
                  ><u><font size="2">§ 36 Abs. 1 Nr. 1-3 und 5 HG </font></u></b
                >
              </p>
              <b>
                <b><p align="CENTER">Hauptberufliche Tätigkeit</p> </b> </b
              >Jahre 1 - 5:
              <b>
                <i><p align="CENTER">Schlüssel 6705</p></i> </b
              >Jahre 6 - 10:
              <b>
                <p align="CENTER">Promotion</p>
                <i><p align="CENTER">Schlüssel 6701</p></i></b
              ><i>
                <hr />
                <p align="CENTER">Abgeschlossenes Hochschulstudium</p>
                <i></i>
                <p align="CENTER"><i>Schlüssel 1230</i></p></i
              >
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><u
                  ><font size="2"
                    ><p align="CENTER">§ 36 Abs. 1 Nr. 1-4 HG</p>
                  </font></u
                >
                <p align="CENTER">Habilitation</p>
                <i><p align="CENTER">Schlüssel 6719</p> </i>
                <hr />
                <b><p align="CENTER">Hauptberufliche Tätigkeit</p> </b> </b
              >Jahre 1 - 5:
              <b>
                <i><p align="CENTER">Schlüssel 6705</p></i> </b
              >Jahre 6 - 10:
              <b>
                <p align="CENTER">Promotion</p>
                <i><p align="CENTER">Schlüssel 6701</p></i>
                <hr />
                <p align="CENTER">Abgeschlossenes Hochschulstudium</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 1230</b></i>
              </p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b
                ><p align="CENTER">Grundwehrdienst oder Zivildienst</p>
                <i></i></b
              ><i></i>
              <p align="CENTER">
                <i><b>Schlüssel 0901</b></i>
              </p>
            </td>
          </tr>
          <tr>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
            <td width="2%" bgcolor="#eeeeee"><p></p></td>
            <td width="23%" valign="TOP" bgcolor="#ffffff" height="2">
              <b><u></u></b>
              <p align="CENTER">
                <b
                  ><u>Allgemeine<br />Schulbildung</u></b
                ><br />
                oder gleichwertiger Bildungsabschluss
              </p>
              <p align="CENTER"><b>nicht anrechenbar</b></p>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

h1 {
  margin-top: 0px;
}
</style>
