<template>
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.881908 12.9714C0.621558 12.7111 0.621558 12.289 0.881908 12.0286L5.9105 7.00002L0.881907 1.97142C0.621558 1.71107 0.621558 1.28897 0.881907 1.02861C1.14226 0.768266 1.56437 0.768266 1.82472 1.02861L6.97105 6.17495C7.19007 6.39366 7.31315 6.69049 7.31315 7.00002C7.31315 7.30955 7.19007 7.60638 6.97105 7.82509L1.82472 12.9714C1.56437 13.2318 1.14226 13.2318 0.881908 12.9714Z"
      fill="#7B7B7B"
    />
  </svg>
</template>
