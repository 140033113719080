<template>
  <div>
    <div id="back" class="flex-item cursor-pointer bg-transparent" @click="navigateOneBack">
      <base-back-arrow class="space-after" />
      <span class="text-big hover:underline">Zurück</span>
    </div>
    <div class="flex-item" :class="{ selected: currentPath === 'Einfuehrung' }">
      <router-link
        class="link-text"
        :to="{ name: 'Einfuehrung' }"
        replace
        :class="{ 'selected-text': currentPath === 'Einfuehrung' }"
        >Einführung</router-link
      >
    </div>
    <div
      class="flex-item"
      :class="{ selected: currentPath === 'Altersgrenze' }"
    >
      <router-link
        class="link-text"
        :to="{ name: 'Altersgrenze' }"
        replace
        :class="{ 'selected-text': currentPath === 'Altersgrenze' }"
        >Gesetzliche Altersgrenze</router-link
      >
    </div>
    <div class="flex-item" :class="{ selected: currentPath === 'Schluessel' }">
      <router-link
        class="link-text"
        :to="{ name: 'Schluessel' }"
        replace
        :class="{ 'selected-text': currentPath === 'Schluessel' }"
        >Schlüsselkatalog</router-link
      >
    </div>
    <div class="flex-item" :class="{ selected: currentPath === 'Regeln' }">
      <router-link
        class="link-text"
        :to="{ name: 'Regeln' }"
        replace
        :class="{ 'selected-text': currentPath === 'Regeln' }"
        >Regelmäßige Arbeitszeit und Pflichtstunden</router-link
      >
    </div>
    <div class="flex-item" :class="{ selected: currentPath === 'Laufbahn' }">
      <router-link
        class="link-text"
        :to="{ name: 'Laufbahn' }"
        replace
        :class="{ 'selected-text': currentPath === 'Laufbahn' }"
        >Übersicht Laufbahngruppen</router-link
      >
    </div>
    <div class="flex-item" :class="{ selected: currentPath === 'Example' }">
      <router-link
        class="link-text"
        :to="{ name: 'Example' }"
        replace
        :class="{ 'selected-text': currentPath === 'Example' }"
        >Beispiele</router-link
      >
    </div>
  </div>
</template>

<script>
import BaseBackArrow from "../FormSites/GeneralFormComponents/UiElements/SVG-Icons/BaseBackArrow";


export default {
  components: {
    BaseBackArrow
  },
  data(){
    return{
      hover: false
    }
  },
  computed: {
    currentPath() {
      return this.$route.name;
    }
  },
  methods: {
    navigateTo(name) {
      this.$router.replace({ name });
    },
    navigateOneBack(){
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-item {
  width: 85%;
  min-height: 40px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.link-text {
  width: 100%;
  height: 100%;
  margin-left: 10px;
  font-family: Libre Franklin;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  text-decoration-line: none;

  /* color-blue-800 */

  color: #0f3b63;
}

.link-text:hover {
  text-decoration-line: underline;
}

.selected-text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;

  /* color-neutral-100 */

  color: #ffffff;
}

.selected {
  background: #286093;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 2px 0px;
  padding: 6px 0px;
}

.pointer {
  cursor: pointer;
}
.space-after{
  margin-right: 1rem;
}

#back:hover span{
  cursor: pointer;
  text-decoration-line: underline;
}
</style>
