<template>
  <div>
    <section>
      <h1 class="headline-large">Einführung</h1>
    </section>
    <section>
      <h2 class="headline-medium">Gesetzliche Altersgrenzen</h2>
      <p>
        Die gesetzliche Altersgrenze ist abhängig vom Geburtsjahr der
        betroffenen Person. Dieser Übersicht können Sie die - nach
        aktueller Rechtslage - geltende Altersgrenze entnehmen.
      </p>
    </section>
    <section>
      <h2 class="headline-medium">Schlüsselkatalog</h2>
      <p>
        Jeder Zeitraum Ihrer Dienstzeiten muss mit einem Schlüssel klassifiziert
        werden.
      </p>
    </section>
    <section>
      <h2 class="headline-medium">Regelm. Arbeitszeit, Pflichtstunden</h2>
      <p>
        Wenn Sie in Teilzeit gearbeitet haben, müssen Sie für diesen Zeitraum
        den Teilzeitbruch angeben.
      </p>
      <p>
        Sie können als Teilzeit-Zähler Ihre individuelle Stundenzahl angeben,
        dann muss der Teilzeit-Nenner die für diesen Zeitraum geltenden
        Pflichtstunden enthalten (z.B. 20/38,5). Möglich ist aber auch z.B. 1/2
        oder 3/4.
      </p>
    </section>
    <section>
      <h2 class="headline-medium">Übersicht Laufbahngruppen</h2>
      <p>
        Diese Aufstellungen sollen die laufbahnrechtliche Zuordnung des eigenen
        beruflichen Werdegangs ermöglichen.
      </p>
      <p>
        Aus dieser Zuordnung lassen sich die entsprechenden Anforderungen, deren
        versorgungsrechtliche Bewertung und die notwendigen Eingabeschlüssel
        leichter feststellen.
      </p>
    </section>
    <section>
      <h2 class="headline-medium">Beispiele</h2>
      <p>
        Sehen Sie sich die Beispiele an.
      </p>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

h1 {
  margin-top: 0px;
}
</style>
