<template>
  <div role="group">
    <div class="flex flex-row flex-wrap justify-start items-center w-full column-item">
      <h2 class="headline-medium flex flex-row flex-nowrap justify-start break-words mr-1" :aria-labelledby="`label-${compId}`">
        {{ title }}
      </h2>
      <img
          role="listitem"
          class="mr-1"
          src="../../../assets/icon/star.svg"
          alt="Star Icon"
      />
      <CircledQuestionMark
        role="navigation"
        :aria-label="hilfeLabel"
        tabindex="0"
        class="mr-1 text-large text-centered cursor-pointer"
        color-type="black"
        @click="$emit('show-help')"
        @keydown.enter="$emit('show-help')"
      />
    </div>
    <SelectBoxBtn
      :label="title"
      :comp-id="compId"
      :tab-indexe="tabIndexe"
      :boxes="boxes"
      :labeldBy="title"
      @select-box="$emit('select-box', $event)"
    />
  </div>
</template>

<script>
import SelectBoxBtn from "./UiElements/SelectBoxBtn";
import CircledQuestionMark from "@/components/FormSites/GeneralFormComponents/UiElements/SVG-Icons/CircledQuestionMark";
export default {
  components: {
    SelectBoxBtn,
    CircledQuestionMark
  },
  props: {
    compId: String,
    title: String,
    boxes: Array,
    tabIndexe: Array,
    hilfeLabel: String,
    showHelpIcon: Boolean
  },
  emits: ["select-box", "show-help"],
  computed: {
  },
};
</script>

<style scoped>
.test{
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
</style>
