<template>
  <div class="backdrop" @click="$emit('close-modal')"></div>
  <div
    id="help-modal"
    class="modal"
    role="alertdialog"
    :aria-live="!isHidden ? 'assertive' : 'off'"
    :aria-hidden="isHidden"
    aria-modal="true"
    aria-labelledby="Hilfe Fenster"
    aria-describedby="slot"
    tabindex="0"
  >
    <div class="flex-container">
      <BaseX id="base-x" @click="$emit('close-modal')" />
    </div>
    <slot class="my-5"></slot>
  </div>
</template>

<script>
import { KEY_ESCAPE } from "keycode-js";
import BaseX from "../FormSites/GeneralFormComponents/UiElements/SVG-Icons/BaseX";

export default {
  components: {
    BaseX
  },
  emits: ["close-modal"],
  props: {
    isHidden: Boolean
  },
  methods: {
    keydown() {
      if (KEY_ESCAPE) {
        this.$emit("close-modal");
      }
    }
  },
  mounted() {
    document.addEventListener("keydown", this.keydown);
  },
  unmounted() {
    document.removeEventListener("keydown", this.keydown);
  }
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#base-x:hover {
  cursor: pointer;
}

.modal {
  padding: 2rem;
  display: flex;
  flex-flow: column;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 40vw;
  height: 100%;
  z-index: 1000;

  border-left: 2px solid #aaaaaa;
  background: #ffffff;
  box-shadow: -5px 0px 20px 1px #aaaaaa;

  animation-name: "blend-in";
  animation-duration: 10s;

  overflow: auto;
  /* overflow-y: scroll;
  overflow-x: scroll; */
}

.backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(235, 235, 235, 0.733);
  filter: blur(8px);
}

@keyframes blend-in {
  from {
    width: 0px;
  }

  to {
    width: 500px;
  }
}
</style>
