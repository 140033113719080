<template>
  <base-help-modal
    v-if="showHelp"
    @close-modal="hideHelpModal"
    aria-live="polite"
    aria-hidden="showHelp"
  >
    <!-- <TheHelpRules /> -->
    <TheHelpKeys />
  </base-help-modal>

  <section :class="{'overflow-hidden': showHelp}">
    <div class="mb-5 flex flex-row flex-wrap justify-start items-center">
      <h1 class="headline-large column-item flex flex-row flex-nowrap justify-start items-center">
        Zeiträume Ihrer Dienstzeiten
      </h1>
      <img role="listitem" class="row-item" src="../../assets/icon/star.svg" alt="Star Icon" />
      <CircledQuestionMark
          v-if="true"
          role="navigation"
          :aria-label="hilfeLabel"
          tabindex="0"
          class="row-item text-large text-centered cursor-pointer"
          color-type="black"
          @click="showHelpModal"
          @keydown.enter="showHelpModal"
      />
    </div>
    <p class="my-5">
      Die nachfolgenden Aufstellungen sollen die laufbahnrechtliche Zuordnung des eigenen beruflichen
      Werdegangs ermöglichen.
    </p>
    <p class="my-5">
      Der angegebene Start der Versorgung ist am <b><u>{{ supplyBegin }}</u></b>
    </p>
    <!--  mt-20 grid grid-flow-row w-cc-screen md:w-full overflow-x-scroll-->
    <dynamic-input-table>
      <dynamic-row
          v-for="(row, index) in rowArray"
          :row="row"
          :key="row.id"
          :idx="index"
          :selectOptions="getOptionMenus"

          :focused="focused"
          :validationErrors="validationErrors"
          :revalidateCtx="revalidateCtx"
          :revalidate-bus="revalBus"
          :intersections="intersections"
          :intersection-ctx="intersectionCtx"
          @publish-validation="handlePublish"
          @delete-row="deleteRow"
          @reset-submit-val="resetSubmit"
      ></dynamic-row>
    </dynamic-input-table>
    <div
        role="button"
        aria-labelledby="dienstzeitraum-btn-label"
        class="add-row-container"
        tabindex="0"
        @click="addRow"
        @keydown.enter="addRow"
        ref="addContainer"
    >
      <BaseCross />
      <span id="dienstzeitraum-btn-label" class="add-button-font">Dienstzeitraum hinzufügen</span>
    </div>
    <div role="form" class="btn-container">
      <button class="btn-secondary text-large text-cc-blue-600" @click="routeToGeneral">
        Zurück
      </button>
      <button
          class="btn-primary text-large text-white"
          :class="{'not-allowed': submitValidationError}"
          @click="routeToResult"
          :disabled="submitValidationError"
      >
        Ruhegehaltssatz berechnen
      </button>
    </div>
  </section>
</template>

<script>
import DynamicInputTable from "./GeneralFormComponents/UiElements/DynamicInputComponents/DynamicInputTable";
import DynamicRow from "./GeneralFormComponents/UiElements/DynamicInputComponents/DynamicRow";
import BaseCross from "./GeneralFormComponents/UiElements/SVG-Icons/BaseCross";
import TheHelpKeys from "../Help/TheHelpKeys";
import BaseHelpModal from "../Help/BaseHelpModal";
import CircledQuestionMark from "@/components/FormSites/GeneralFormComponents/UiElements/SVG-Icons/CircledQuestionMark";
import {sortTimeFrames, timeStampToDateString} from "@/helper/helper";
import RevalidationBus from "@/helper/revalidationBus";
import mitt from "mitt";


export default {
  components: {
    DynamicInputTable,
    DynamicRow,
    BaseCross,
    TheHelpKeys,
    BaseHelpModal,
    CircledQuestionMark
  },
  data() {
    return {
      numberOfRows: 1,
      /** @type {import("@/types").ValidationErrType[]} */
      validationErrors: [],
      submitValidationError: false,
      showHelp: false,
      focused: null,
      missingPeriodsFields: [],
      revalBus: new RevalidationBus(),
      intersections: [],
      intersectionCtx: mitt()
    };
  },
  methods: {
    resetIntersections(){
      this.intersections = [];
    },
    resetSubmit(){
      this.submitValidationError = false;
    },
    addRow() {
      const addContainer = this.$refs.addContainer;
      if (addContainer?.matches(":focus")) addContainer.blur();
      const rowId = Date.now();
      this.$store.dispatch({
        type: "generatePeriodRow",
        rowId
      });
      this.focused = rowId;
    },
    deleteRow(deleteRow) {
      this.rowArray = this.rowArray.filter(row => row.id !== deleteRow.id);
    },
    routeToGeneral() {
      this.$router.push("/");
    },
    routeToResult() {
      let statesValid = false;
      this.$store.dispatch("isErrStateValid", inptsValid => {
        if (inptsValid) {
          statesValid = true;
        } else {
          this.submitValidationError = true;
        }
      });
      Promise.all(this.revalBus.revalidate())
          .then(() => {
            if(statesValid){
              this.$store.dispatch("sendRequest", this.$router);
            }
          })
          .catch(({reason, key}) => {
            try {
              this.submitValidationError = true;
              if(reason === "Validation Response ist undefined oder null."){
                console.error(reason, "für den key ", key);
              }
              if(key.includes("checkFramesForIntersections")){
                const fragments = reason.split("\n");
                this.intersections = fragments.filter(fragmentStr => fragmentStr.length > 0).map(fragmentStr => JSON.parse(fragmentStr));
              }
            }catch (e){
              console.error(e);
              if(e.message?.includes("undefined")){
                console.log("Key: ", key);
                console.log("reason: ", reason);
                console.log("this.intersections: ", this.intersections);
              }
            }
          });
    },
    checkForMissingFields(){
      this.rowArray.forEach(row => {
        const missingFields = {
          rowNum: this.$store.getters.searchForPeriodRowNum(row.id),
          from: false,
          to: false,
          employmentKey: false,
          fractureCounter: false,
          fractureDenom: false
        }
        if(!row.workPeriod?.from) missingFields.from = true;
        if(!row.workPeriod?.to) missingFields.to = true;
        if(!row.employment) missingFields.employmentKey = true;
        if(row.employment?.text?.toLowerCase().includes("teil")){
          if(row.fracture !== undefined && isNaN(row.fracture?.counter)){
            missingFields.fractureCounter = true;
          }
          if(row.fracture !== undefined &&  isNaN(row.fracture?.denominator)){
            missingFields.fractureDenom = true;
          }
        }
        if(missingFields.from || missingFields.to || missingFields.employmentKey || missingFields.fractureCounter || missingFields.fractureDenom){
          this.missingPeriodsFields.push(missingFields);
        }
      })
    },
    handlePublish(pubObj) {
      this.submitValidationError = false;
      let validationErr = this.validationErrors.find(elem => elem.index === pubObj.index);
      if (pubObj.reset && validationErr) {
        this.removeValidationMsg(validationErr, pubObj);
        this.removeEmptyErrorobj(validationErr);
      }
      if (!validationErr && !pubObj.reset) {
        validationErr = {
          index: pubObj.index,
          from: [],
          to: [],
          fraction: [],
          eligible: [],
          period: []
        };
        this.updateValidationObj(validationErr, pubObj);
        this.validationErrors.push(validationErr);
        this.$store.dispatch("addTimelineError", validationErr);
      } else {
        if (!pubObj.reset) this.updateValidationObj(validationErr, pubObj);
      }
      if(process.env.NODE_ENV === "development"){
        console.log("handlePublish | getTimelinePageErrs: ", this.$store.getters.getTimelinePageErrs);
      }
    },
    updateValidationObj(validationErr, pubObj) {
      switch (pubObj.source) {
        case "from":
          this.$store.dispatch("updateTimelinePageErr", pubObj)
          if (
            !validationErr.from.find(
              msgObj => msgObj.msg === pubObj.msg && msgObj.key === pubObj.inpElem
            )
          ) {
            validationErr.from.push({
              key: pubObj.inpElem,
              msg: pubObj.msg
            });
          }
          break;
        case "to":
          this.$store.dispatch("updateTimelinePageErr", pubObj)
          if (
            !validationErr.to.find(
              msgObj => msgObj.msg === pubObj.msg && msgObj.key === pubObj.inpElem
            )
          ) {
            validationErr.to.push({
              key: pubObj.inpElem,
              msg: pubObj.msg
            });
          }
          break;
        case "frac":
          this.$store.dispatch("updateTimelinePageErr", pubObj)
          if (
            !validationErr.fraction.find(
              msgObj => msgObj.msg === pubObj.msg && msgObj.key === pubObj.inpElem
            )
          ) {
            validationErr.fraction.push({
              key: pubObj.inpElem,
              msg: pubObj.msg
            });
          }
          break;
        case "elig":
          this.$store.dispatch("updateTimelinePageErr", pubObj)
          if (
            !validationErr.eligible.find(
              msgObj => msgObj.msg === pubObj.msg && msgObj.key === pubObj.inpElem
            )
          ) {
            validationErr.eligible.push({
              key: pubObj.inpElem,
              msg: pubObj.msg
            });
          }
          break;
        case "period":
          this.$store.dispatch("updateTimelinePageErr", pubObj)
          if (!validationErr.period.find(msgObj => msgObj.key === pubObj.inpElem)) {
            validationErr.period.push({
              key: pubObj.inpElem,
              msg: pubObj.msg
            });
          }
          break;
        default:
          break;
      }
    },
    //TODO Check if removeValidationMsg does have any effect
    //FIXME Check if removeValidationMsg does have any effect
    removeValidationMsg(validationErr, pubObj) {
      let msgObj;
      this.$store.dispatch("removeTimelineValidationMsg", pubObj);
      switch (pubObj.source) {
        case "from":
          msgObj = validationErr.from.find(msgObj => msgObj.key === pubObj.inpElem);
          if (msgObj) {
            validationErr.from.splice(validationErr.from.indexOf(msgObj), 1);
          }
          break;
        case "to":
          msgObj = validationErr.to.find(msgObj => msgObj.key === pubObj.inpElem);
          if (msgObj) {
            validationErr.to.splice(validationErr.from.indexOf(msgObj), 1);
          }
          break;
        case "frac":
          msgObj = validationErr.fraction.find(msgObj => msgObj.key === pubObj.inpElem);
          if (msgObj) {
            validationErr.fraction.splice(validationErr.from.indexOf(msgObj), 1);
          }
          break;
        case "elig":
          msgObj = validationErr.eligible.find(msgObj => msgObj.key === pubObj.inpElem);
          if (msgObj) {
            validationErr.eligible.splice(validationErr.from.indexOf(msgObj), 1);
          }
          break;
        case "period":
          msgObj = validationErr.period.find(msgObj => msgObj.key === pubObj.inpElem);
          if (msgObj) {
            validationErr.period.splice(validationErr.period.indexOf(msgObj), 1);
          }
          break;
        case "row":
          this.$store.dispatch("removeTimelineErrorRow", {validationErr, force: true});
          this.validationErrors = this.validationErrors.filter(err => err.index !== pubObj.index)

          this.validationErrors.forEach(err => {
            if(err.index > 0){
              err.index--;
            }
          });

          break;
        default:
          break;
      }
    },
    removeEmptyErrorobj(validationErr) {
      this.$store.dispatch("removeTimelineErrorRow", { validationErr });
      for (const key in validationErr) {
        if (Array.isArray(validationErr[key]) && validationErr[key].length > 0) {
          return;
        }
      }
      this.validationErrors.splice(this.validationErrors.indexOf(validationErr), 1);
    },
    showHelpModal() {
      this.showHelp = true;
    },
    hideHelpModal() {
      this.showHelp = false;
    },
    onKeyAdded() {
      this.missingPeriodsFields = [];
      this.checkForMissingFields();
      if(this.missingPeriodsFields.length === 0) this.submitValidationError = false
    },
    /**
     *
     * @returns {import("@/types").RevalidationResult}
     */
    checkFramesForIntersections(){
      /** @type {import("@/types").Period[]} */
      const periods = this.$store.getters.getServicePeriods;
      const sortedPeriods = sortTimeFrames(periods);
      const foundIntersections = [];

      for(let i = 1; i < sortedPeriods.length; i++){
        if(this.checkForIntersection(sortedPeriods[i-1].workPeriod, sortedPeriods[i].workPeriod)){
          foundIntersections.push({periodA: sortedPeriods[i-1].id, periodB: sortedPeriods[i].id})
        }
      }

      if(foundIntersections.length === 0) return { valid: true, reason: "" };

      let reason = ""
      foundIntersections.forEach(intersection => {
        reason += JSON.stringify(intersection) + "\n";
      })

      return { valid: false, reason };
    },
    checkForIntersection(workPeriodA , workPeriodB){
      return workPeriodA.to >= workPeriodB.from;
    }
  },
  computed: {
    birthdateErr() {
      return this.$store.getters.getGeneralPageErrs.find(err => err.elem === "birthdate");
    },
    supplyBegin() {
      const supplybeginTimestamp = this.$store.getters.getBegin;
      if(!supplybeginTimestamp) return "\"Fehler\"";
      const supplybeginDate = new Date(supplybeginTimestamp);
      // return supplybeginDate.toLocaleDateString("de-DE");
      return timeStampToDateString(supplybeginDate.getTime());
    },
    getOptionMenus() {
      return this.$store.getters.getOptionMenus;
    },
    rowArray() {
      return this.$store.getters.getServicePeriods;
    },
  },
  watch: {
    showHelp(){
      const body = document.body;
      if(!this.showHelp){
        body.classList.remove("overflow-hidden")
        return;
      }
      body.classList.add("overflow-hidden");
    }
  },
  created() {
    if (this.rowArray.length === 0) {
      this.addRow();
    }
  },
  mounted() {
    if(!this.$store.getters.getBegin)
      this.$router.replace({name: "General"});
    this.revalBus.add(`timelineInfo-checkFramesForIntersections`, this.checkFramesForIntersections);

    this.$store.getters.getEmploymentCtx.on("key-added", this.onKeyAdded);

    this.intersectionCtx.on("reset", this.resetIntersections);
  },
  unmounted() {
    this.$store.getters.getEmploymentCtx.off("key-added", this.onKeyAdded);

    this.revalBus.remove(`timelineInfo-checkFramesForIntersections`);
  }
};
</script>

<style scoped>
.error-container {
  margin: 2rem 0px;
}

div:focus {
  outline: none;
}

.add-row-container {
  width: 248px;
  height: 40px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0px;

  cursor: pointer;
}

.add-row-container:hover,
.add-row-container:focus {
  background: #e6f0fa;
  border-radius: 4px;
}

.add-button-font {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;

  /* color-blue-600 */

  color: #125797;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 1rem;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.help-icon {
  border: 3px solid #2c2e35;
  border-radius: 50%;
  width: 25px;
  height: 25px;

  cursor: pointer;
}

.text-centered {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.row-item {
  margin-right: 0.5rem;
}

.container-flex-row {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.column-item {
  margin-bottom: 0;
}

.not-allowed {
  cursor: not-allowed;
}
</style>
